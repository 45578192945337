import { Audio } from "expo-av";
import { numberSoundMap } from "./constants";

class soundEffect {
  file: any;
  sound: Audio.Sound;
  constructor(file: any) {
    this.sound = new Audio.Sound();
    this.file = file;
    this.sound
      .loadAsync(this.file)
      .catch((err) => console.log("load audio", err));
  }
  playSound = async (length: number) => {
    await this.sound.playAsync().catch((err) => console.log("play audio", err));
    setTimeout(() => {
      this.sound.stopAsync().catch((err) => console.log("stop audio", err));
      this.sound
        .setPositionAsync(0)
        .catch((err) => console.log("set pos", err));
    }, length);
  };
}

let numberInstructionsAudioFileMap: { [key: number]: soundEffect } = {};
let numberAudioFileMap: { [key: string]: soundEffect } = {}
  
async function loadNumberInstructionsAudioFileMap() {
    const toLoad: { [key: string]: soundEffect } = {
      "1": new soundEffect(require("../assets/sounds/instructions_ga.mp3")),
      "2": new soundEffect(require("../assets/sounds/instructions_sha.mp3")),
      "3": new soundEffect(require("../assets/sounds/instructions_ba.mp3")),
    "4": new soundEffect(require("../assets/sounds/instructions_ta.mp3")),
    "5": new soundEffect(require("../assets/sounds/instructions_ma.mp3")),
    "6": new soundEffect(require("../assets/sounds/instructions_wa.mp3")),
    "7": new soundEffect(require("../assets/sounds/instructions_na.mp3")),
    "8": new soundEffect(require("../assets/sounds/instructions_ha.mp3")),
    "9": new soundEffect(require("../assets/sounds/instructions_ka.mp3")),
  };
  numberInstructionsAudioFileMap = toLoad;
}

async function loadNumberAudioFileMap() {
  const toLoad: { [key: string]: soundEffect } = {
  "1": new soundEffect(require("../assets/sounds/ga.mp3")),
  "2": new soundEffect(require("../assets/sounds/sha.mp3")),
  "3": new soundEffect(require("../assets/sounds/ba.mp3")),
  "4": new soundEffect(require("../assets/sounds/ta.mp3")),
  "5": new soundEffect(require("../assets/sounds/ma.mp3")),
  "6": new soundEffect(require("../assets/sounds/wa.mp3")),
  "7": new soundEffect(require("../assets/sounds/na.mp3")),
  "8": new soundEffect(require("../assets/sounds/ha.mp3")),
  "9": new soundEffect(require("../assets/sounds/ka.mp3")),
};
numberAudioFileMap = toLoad;
}

loadNumberInstructionsAudioFileMap();
loadNumberAudioFileMap();
export { soundEffect, numberAudioFileMap, numberInstructionsAudioFileMap };
