import { supabase } from "./supabase";

interface SupabaseResponse {
  data: any;
  error: any;
}

const getAllUserData = async (
  id: string,
  table: string
): Promise<SupabaseResponse> => {
  return await supabase.from(table).select("*").eq("id", id);
};

const supabaseUpsert = async (table: string, data: any) => {
  // throw an error
  return await supabase
    .from(table)
    .upsert(data)
    .catch((error) => {
      console.log("supabase upsert error", table, data, error);
      return Promise.reject(error);
    });
};

export { getAllUserData, supabaseUpsert };
