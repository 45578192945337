import React, {
  View,
  StyleSheet,
  Text,
  Platform,
  Pressable,
  Switch,
  Linking,
  Alert,
} from "react-native";
import {
  VIEW_ELEVATION,
  learnMoreWidth,
  BUTTON_ELEVATION,
  graphWidth,
  SECONDARY_BUTTON_COLOR,
  SECONDARY_BUTTON_COLOR_PRESSED,
} from "./constants";
import { useState, useEffect } from "react";

const ButtonSwitch = (props: any) => {
  return (
    <View style={styles.buttonSwitch}>
      <Pressable
        elevation={props.isLeft ? 0 : BUTTON_ELEVATION}
        onPress={() => props.setLeftPress(true)}
        style={props.isLeft ? styles.pressedSwitch : styles.unpressedSwitch}
      >
        <Text
          style={[
            styles.switchText,
            props.isLeft ? { textDecorationLine: "underline" } : {},
          ]}
        >
          {props.leftText}
        </Text>
      </Pressable>
      <Pressable
        elevation={props.isLeft ? BUTTON_ELEVATION : 0}
        onPress={() => props.setLeftPress(false)}
        style={props.isLeft ? styles.unpressedSwitch : styles.pressedSwitch}
      >
        <Text
          style={[
            styles.switchText,
            props.isLeft ? {} : { textDecorationLine: "underline" },
          ]}
        >
          {props.rightText}
        </Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  topcontainer: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  container: {
    width: `${graphWidth}%`,
    alignItems: "center",
  },
  h1: {
    fontSize: 32,
    fontWeight: "bold",
  },
  buttonSwitch: {
    flexDirection: "row",
  },
  pressedSwitch: {
    backgroundColor: SECONDARY_BUTTON_COLOR_PRESSED,
    color: "white",
    borderRadius: 10,
    margin: 10,
    // borderWidth: 30,
    // overflow: "hidden",
    // shadowOffset: { width: 0, height: 0 },
    // shadowColor: "black",
    // shadowRadius: 10,
    // shadowOpacity: 1,
  },
  switchText: {
    color: "white",
    padding: 10,
  },
  unpressedSwitch: {
    backgroundColor: SECONDARY_BUTTON_COLOR,
    color: "white",
    margin: 10,
    borderRadius: 10,
    shadowRadius: 1,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
  },
});

export default ButtonSwitch;
