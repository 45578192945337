import { createClient } from "@supabase/supabase-js";
import "react-native-url-polyfill/auto";
import AsyncStorage from "@react-native-async-storage/async-storage";

const supabaseUrl = "https://risxtejhxqbeovnwumfw.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJpc3h0ZWpoeHFiZW92bnd1bWZ3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzQxODMwMzMsImV4cCI6MTk4OTc1OTAzM30.QAyjCCjMUPoxloleb5YZyeOCDRENpLCRlzZgy05vDJA";

const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    storage: AsyncStorage as any,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
});

export { supabase };
