import React, { View, Text, Modal, TouchableOpacity } from "react-native";

import { useState, useEffect, useRef } from "react";
import { VIEW_ELEVATION } from "./constants";

import { default as Octicon } from "react-native-vector-icons/Octicons";
import { useLinkPressHandler } from "react-router-native";

const ShowHelpModal = (props: any) => {
  const [modalVisible, setModalVisible] = useState(props.showHelp);

  useEffect(() => {
    setModalVisible(props.showHelp);
  }, [props.congratsPassedBeginner]);

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        // height: "100%",
        // backgroundColor: "red",
      }}
      onRequestClose={() => {
        setModalVisible(!modalVisible);
      }}
    >
      {/* <Background> */}
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "rgba(0,0,0,0.5)",
        }}
        activeOpacity={1}
        // style={{ backgroundColor: "blue" }}
        onPress={() => {
          setModalVisible(false);
        }}
      >
        {/* <View
          style={{
            width: "100%",
            height: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "rgba(0,0,0,0.5)",
          }}
        > */}
        <View
          elevation={VIEW_ELEVATION}
          style={[
            {
              backgroundColor: "white",
              // width: "90%",
              maxWidth: "90%",
              minHeight: "50%",
              borderRadius: 20,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              padding: 30,
              shadowRadius: 2,
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.8,
            },
          ]}
        >
          <Octicon
            name="x"
            size={28}
            onPress={() => {
              setModalVisible(false);
            }}
            style={{
              position: "absolute",
              left: 15,
              right: 0,
              top: 10,
              bottom: 0,
            }}
          />
          <Text style={{ textAlign: "center", fontSize: 48 }}>
            Instructions
          </Text>
          <Text style={{ fontSize: 20, marginTop: 20 }}>
            Press every number except {props.badNumber}. If you press{" "}
            {props.badNumber}, or if you miss pressing any other number, you
            will lose points. If you lose too many points the game will end.
          </Text>
          <Text style={{ fontSize: 20, marginTop: 20 }}>
            The number that you're not supposed to press will change every time,
            so next game it won't be {props.badNumber} anymore. Before you press
            "Start" make sure to check which number to avoid.
          </Text>
          <Text style={{ fontSize: 20, marginTop: 20 }}>
            Keep practicing, and soon you'll have amazing focus ability! 😃
          </Text>
        </View>
        {/* </View> */}
      </TouchableOpacity>
      {/* </Background> */}
    </Modal>
  );
};

export default ShowHelpModal;
