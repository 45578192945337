import React, { useState, useEffect, Component } from "react";
import { View, Text } from "react-native";
import * as Font from "expo-font";

const sToTime = (duration: number) => {
  let seconds = Math.floor(duration);
  let hours = Math.floor(seconds / 3600);
  seconds = seconds % 3600;
  let minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  const minutesPad = minutes < 10 ? "0" : "";
  const secondsPad = seconds < 10 ? "0" : "";
  return `${hours} : ${minutesPad}${minutes} : ${secondsPad}${seconds}`;
};

type ClockProps = {
  startTime: number;
  stoppedTime: number;
  pageType: string;
};

type ClockState = {
  startTime: number;
  timeElapsed: number;
  intervalTrack: number;
  stoppedTime: number;
  fontLoaded: boolean;
  pageType: string;
};

class Clock extends Component<ClockProps, ClockState> {
  constructor(props: ClockProps) {
    super(props);
    this.state = {
      startTime: props.startTime,
      stoppedTime: 0,
      timeElapsed: 0,
      intervalTrack: -1,
      fontLoaded: false,
      pageType: props.pageType,
    };
  }

  componentDidMount(): void {
    this.loadFonts();
  }

  componentDidUpdate(
    prevProps: Readonly<ClockProps>,
    prevState: Readonly<ClockState>
  ): void {
    if (this.state.intervalTrack == -1 && this.props.pageType == "testing") {
      const interval = setInterval(() => {
        const currentTime = new Date().getTime();
        this.setState({ timeElapsed: currentTime - this.state.startTime });
      }, 500);
      this.setState({
        intervalTrack: interval as unknown as number,
        pageType: this.props.pageType,
      });
    }

    const toSet: any = {};
    if (this.props.pageType != this.state.pageType) {
      toSet["pageType"] = this.props.pageType;
    }
    if (this.props.pageType == "start" && this.state.timeElapsed != 0) {
      toSet["timeElapsed"] = 0;
    }
    if (this.props.pageType != "testing" && this.state.intervalTrack != -1) {
      clearInterval(this.state.intervalTrack);
      toSet["intervalTrack"] = -1;
    }
    if (this.props.startTime != this.state.startTime) {
      toSet["startTime"] = prevProps.startTime;
    }
    if (prevProps.stoppedTime != this.state.stoppedTime) {
      toSet["stoppedTime"] = prevProps.stoppedTime;
    }
    if (Object.keys(toSet).length > 0) {
      this.setState(toSet);
    }
  }

  loadFonts = async () => {
    await Font.loadAsync({
      "orloj-font": require("../assets/fonts/orloj-font/Orloj-Ea9eW.otf"),
    });
    this.setState({ fontLoaded: true });
  };

  render = () => {
    if (this.state.fontLoaded && this.state.pageType != "start") {
      const timeToDisplay =
        this.state.pageType == "done"
          ? this.state.stoppedTime
          : (new Date().getTime() - this.state.startTime) / 1000;
      return (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <Text style={{ fontFamily: "orloj-font", fontSize: 50 }}>
            {sToTime(timeToDisplay)}
          </Text>
        </View>
      );
    } else {
      return null;
    }
  };
}

export default Clock;
