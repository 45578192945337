import React, {
  View,
  StyleSheet,
  Text,
  Platform,
  Pressable,
  Switch,
  Linking,
  Alert,
} from "react-native";
import { VIEW_ELEVATION, learnMoreWidth } from "./constants";
import { useState, useEffect } from "react";
import { Auth } from "./signin";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import ButtonSwitch from "./button_switch";
import { useLinkPressHandler } from "react-router-native";

// react native functional component with a view and a switch
const SettingsRow = (props: any) => {
  return (
    <View
      style={[
        { flexDirection: "row", justifyContent: "space-between" },
        props.style,
      ]}
    >
      <View
        style={{
          width: "80%",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Text style={styles.text}>{props.text}</Text>
        {props.explainerText ? (
          <Text style={{ marginLeft: 20 }}>{props.explainerText}</Text>
        ) : null}
      </View>
      <Switch
        // ios_backgroundColor="blue"
        trackColor={{ false: "#767577", true: "#81b0ff" }}
        thumbColor={props.value ? "white" : "white"}
        onValueChange={props.onValueChange}
        value={props.value}
      />
    </View>
  );
};

const ContactUs = (props: any) => {
  const insets = useSafeAreaInsets();
  const to = "support@brainlaserapp.com";
  const subject = "Brain Laser Support";
  let url = `mailto:${to}?subject=${subject}`;

  const onPress = async () => {
    const supported = await Linking.canOpenURL(url);
    if (!supported) {
      Alert.alert("Unable to open email app", `Please send email to ${to}`);
      return null;
    }
    return Linking.openURL(url);
  };

  return (
    <View
      style={{
        flexDirection: "column",
        justifyContent: "flex-start",
        marginTop: 10,
        marginBottom: insets.bottom + 40,
      }}
    >
      {/* <Pressable onPress={onPress}> */}
      <Text
        style={{
          fontSize: 24,
          textAlign: "center",
        }}
      >
        Contact Us:
      </Text>
      <Text
        style={{
          textDecorationLine: "underline",
          fontSize: 24,
          textAlign: "center",
        }}
      >
        support@brainlaserapp.com
      </Text>
      {/* </Pressable> */}
    </View>
  );
};

const SettingsPage = (props: any) => {
  const goToHome = useLinkPressHandler("/home");
  useEffect(() => {
    if (props.isTesting && !props.doneTesting) {
      goToHome();
      props.endTesting();
    }
  }, [props.isTesting, props.doneTesting]);

  const [email, setEmail] = useState(props.email);
  const [loginStatus, setLoginStatus] = useState(props.loginStatus);
  const [session, setSession] = useState(props.session);

  useEffect(() => {
    setSession(props.session);
  }, [props.session]);

  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);
  useEffect(() => {
    setLoginStatus(props.loginStatus);
  }, [props.loginStatus]);

  return (
    <View
      style={{
        flexDirection: "column",
        width: learnMoreWidth,
        // height: "100%",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: 20,
        }}
      >
        <Text style={{ fontSize: 36 }}>Settings</Text>
      </View>
      <View
        elevation={VIEW_ELEVATION}
        style={[
          styles.shadow,
          { backgroundColor: "white", borderRadius: 10, marginBottom: 20 },
        ]}
      >
        <View style={{ marginHorizontal: 5, marginVertical: 5 }}>
          <SettingsRow
            text="Beginner Mode"
            onValueChange={props.setSlowMode}
            value={props.slowMode}
            style={{ marginBottom: 5 }}
          />
          <SettingsRow
            text="Perfection Mode"
            onValueChange={(x: boolean) => props.setContinuousMode(!x)}
            value={!props.continuousMode}
            explainerText="In Perfection Mode, the game ends when you make a mistake."
            style={{ marginBottom: 5 }}
          />

          {/* <ButtonSwitch
            leftText="Continuous Mode"
            rightText="Perfection Mode"
            isLeft={props.continuousMode}
            setLeftPress={props.setContinuousMode}
          /> */}
          <SettingsRow
            text="Use Sound"
            onValueChange={props.toggleAudio}
            value={props.useAudio}
            style={{ marginBottom: 5 }}
          />
          {/* <SettingsRow
            text={
              "Automatic start again after finishing\n(press cancel to end)"
            }
            onValueChange={props.toggleContinuousTesting}
            value={props.continuousTesting}
          /> */}
        </View>
      </View>
      {/* <Auth
        setEmail={props.setEmail}
        email={email}
        setSession={props.setSession}
        loginStatus={loginStatus}
        setLoginStatus={props.setLoginStatus}
        session={session}
      ></Auth> */}
      <ContactUs />
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: Platform.OS === "web" ? 18 : 18,
  },
  shadow: {
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
  },
});

export default SettingsPage;
