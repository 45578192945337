const nextSartNumber = (
  badNumber: number,
  allowBadNumber: boolean = true,
  slowMode: boolean = false
) => {
  const randomVal = Math.random();
  // if (badNumber !== 0) return badNumber;
  const thresholdProb = slowMode ? 0.25 : 0.07;
  if (randomVal < thresholdProb && badNumber > 0 && allowBadNumber) {
    return badNumber;
  } else {
    return choose(
      [1, 2, 3, 4, 5, 6, 7, 8, 9].filter((n: number) => n != badNumber)
    );
  }
};

const getSartValues = (numValues: number, numThrees: number) => {
  let values = [];
  for (let i = 0; i < numValues - numThrees; i++) {
    values.push(choose([1, 2, 4, 5, 6, 7, 8, 9]));
  }
  values = [...values, ...Array(numThrees).fill(3)];
  return shuffleArray(values);
};

function shuffleArray(array: number[]) {
  // Stolen from https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const choose = (choices: any[]): any => {
  const index = Math.floor(Math.random() * choices.length);

  return choices[index];
};

const randomDifferentNumber = (notThisNumber: number): number => {
  return choose(
    [1, 2, 3, 4, 5, 6, 7, 8, 9].filter((n: number) => n != notThisNumber)
  );
};

export { nextSartNumber, getSartValues, choose, randomDifferentNumber };
