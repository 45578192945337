import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Dimensions,
  ScrollView,
  StyleSheet,
  Platform,
} from "react-native";
import {
  SECONDARY_BUTTON_COLOR,
  SECONDARY_BUTTON_COLOR_PRESSED,
  VIEW_ELEVATION,
} from "./constants";
const screenWidth = Dimensions.get("window").width;
import {
  getDataByDate,
  getTodayDateString,
  addLists,
} from "./data_manipulation";
import { graphWidth } from "./constants";
import Icon from "react-native-vector-icons/Octicons";
import { useLinkPressHandler } from "react-router-native";
// import landingPageData from "./landing_page_graph_data";
import ButtonSwitch from "./button_switch";
import Graph, { sum } from "./Graph";
import { unstable_batchedUpdates } from "react-native";
// import { backupData } from "./backupData";

const StatsBlock = (props: any) => {
  const fontSize = 17;
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 2,
      }}
    >
      <Text style={{ fontSize: fontSize, color: "hsla(217, 0%, 20%, 1)" }}>
        {props.text}
      </Text>
      <Text style={{ fontSize: fontSize, fontWeight: "bold" }}>
        {props.value}
      </Text>
    </View>
  );
};

const getDataSafe = (d: any, field: string): number => {
  return d[field] !== undefined ? d[field] : 0;
};

const gettotaltime = (d: any): number => {
  return d.meanscoretoday !== undefined && d.totaltrialstoday !== undefined
    ? d.meanscoretoday * d.totaltrialstoday
    : 0;
};

const cumSum = (arr: number[]) => {
  const result = [];
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    sum += arr[i];
    result.push(sum);
  }
  return result;
};

const convertSecondsToTimeString = (timeSeconds: number) => {
  // convert the time in seconds to either minutes or hours or days, with 2 decimal places
  let returnString = "";
  if (timeSeconds > 3600 * 24) {
    returnString += Math.floor(timeSeconds / 3600 / 24) + "d ";
    timeSeconds = timeSeconds % (3600 * 24);
  }
  if (timeSeconds > 3600) {
    returnString += Math.floor(timeSeconds / 3600) + "h ";
    timeSeconds = timeSeconds % 3600;
  }
  if (timeSeconds > 60) {
    returnString += Math.floor(timeSeconds / 60) + "min ";
    timeSeconds = timeSeconds % 60;
  }
  if (timeSeconds >= 0) {
    returnString += timeSeconds.toFixed(0) + "s";
  }
  return returnString;
};

const getConsecutiveDays = (data: any) => {
  if (data.length == 0) {
    return 0;
  }
  let count = data[data.length - 1] > 0 ? 1 : 0;
  let index = data.length - 2;
  while (index >= 0 && data[index] > 0) {
    count += 1;
    index -= 1;
  }
  return count;
};

const GraphPage = (props: any) => {
  const goToHome = useLinkPressHandler("/home");
  useEffect(() => {
    if (props.isTesting && !props.doneTesting) {
      goToHome();
      props.endTesting();
    }
  }, [props.isTesting, props.doneTesting]);

  const debugMode = false;

  const [isLoading, setIsLoading] = useState(false);

  const [graphData, setGraphData] = useState<any>({
    labels: [],
    bestscoreData: [],
    worstscoreData: [],
    meanscoreData: [],
  });
  const [continuousGraphData, setContinuousGraphData] = useState<any>({
    labels: [],
    ratioscoreData: [],
    pointsscoreData: [],
  });

  const [stats, setStats] = useState<any>({
    personalBest: "0s",
    bestscore30DayAverage: "0s",
    bestscore7DayAverage: "0s",
    totaltrials: 0,
    totalPracticeDays: 0,
  });
  const [continuousStats, setContinuousStats] = useState<any>({
    personalBest: 0,
    bestscore7DayAverage: 0,
  });
  const [dailyTimeData, setDailyTimeData] = useState<any>([]);
  const [graph30DayToggle, setGraph30DayToggle] = useState(true);

  // is bestscoreToday needed? propbably just for forcing updates
  const [bestscoreToday, setbestscoreToday] = useState(0);
  const [numberActiveDays, setNumberActiveDays] = useState(0);

  const getOverallScore = (bestscores: any, window: number) => {
    let daysWindow = bestscores.slice(-window);
    if (bestscores.length == 0) {
      return "0s";
    }
    const overallScoreSeconds =
      daysWindow.reduce((a: number, b: number) => a + b, 0) /
      getAverageDenominator(daysWindow, window);
    return convertSecondsToTimeString(overallScoreSeconds);
  };

  const getAverageDenominator = (scores: number[], targetLen: number) => {
    if (scores.length >= targetLen) {
      return targetLen;
    }
    let ind = 0;
    while (scores[ind] == 0 && ind < scores.length) {
      ind += 1;
    }
    return Math.max(1, scores.length - ind);
  };

  const continuousModeUpdate = () => {
    let toReturn: any = getDataByDate(true, true)
      .then((dat) => {
        let toReturn: any = {};
        const labels = dat.map((d) => d.localdate);
        const totaltimePerDay = dat.map((d) =>
          d.sessiontime ? d.sessiontime : 0
        );
        const ratioscore = dat.map((d) => getDataSafe(d, "ratioscore"));
        const pointsscore = dat.map((d) => getDataSafe(d, "pointsscore"));

        toReturn["numberActiveDays"] = pointsscore.filter(
          (x: number) => x > 0
        ).length;

        toReturn["continuousStats"] = {
          bestscore7DayAverage: parseFloat(
            (
              sum(pointsscore.slice(-7)) / getAverageDenominator(pointsscore, 7)
            ).toFixed(1)
          ),
          personalBest: Math.max(...pointsscore),
        };

        toReturn["continuousGraphData"] = {
          labels: labels,
          ratioscoreData: ratioscore,
          pointsscoreData: pointsscore,
        };

        toReturn["continuousDailyTimeData"] = totaltimePerDay;

        return toReturn;
      })
      .catch((err) => {
        console.log("error getting data by date", err);
        return {};
      });
    return toReturn;
  };

  const sliceIfNecessary = (data: any) => {
    return graph30DayToggle ? data.slice(-30) : data;
  };

  const update = () => {
    const toReturn = getDataByDate(true)
      // .then((dat) => dat.slice(0, 64))
      .then((dat) => {
        // dat = backupData;
        const toReturn: any = {};
        const labels = dat.map((d) => d.localdate);
        const totaltimePerDay = dat.map((d) => gettotaltime(d));
        const bestscore = dat.map((d) => getDataSafe(d, "bestscoretoday"));
        const totaltrials = dat.map((d) => getDataSafe(d, "totaltrialstoday"));

        toReturn["numberActiveDays"] = bestscore.filter(
          (x: number) => x > 0
        ).length;
        const personalBest30DayAverage = getOverallScore(bestscore, 30);

        toReturn["stats"] = {
          bestscore30DayAverage: personalBest30DayAverage,
          bestscore7DayAverage: getOverallScore(bestscore, 7),
          personalBest: convertSecondsToTimeString(Math.max(...bestscore)),
          totaltrials: totaltrials.reduce((a: number, b: number) => a + b, 0),
          totalPracticeDays: totaltrials.filter((x: number) => x > 0).length,
          consecutiveDays: getConsecutiveDays(totaltrials),
        };
        toReturn["perfectionDailyTimeData"] = totaltimePerDay;
        toReturn["graphData"] = {
          labels: labels,
          bestscoreData: bestscore,
        };
        if (
          labels.length > 0 &&
          labels[labels.length - 1] == getTodayDateString()
        ) {
          toReturn["bestscoreToday"] = bestscore[bestscore.length - 1];
        } else {
          toReturn["bestscoreToday"] = 0;
        }
        return toReturn;
      })
      .catch((err) => {
        console.log("error getting data by date", err);
        return {};
      });
    return toReturn;
  };

  const batchUpdate = (values: any) => {
    const contStatsDict: any = values[0];
    const statsDict: any = values[1];
    setContinuousStats(contStatsDict["continuousStats"]);
    setContinuousGraphData(contStatsDict["continuousGraphData"]);
    // setNumberActiveDays(
    //   Math.max(contStatsDict["numberActiveDays"], statsDict["numberActiveDays"])
    // );
    setStats(statsDict["stats"]);
    setGraphData(statsDict["graphData"]);
    setbestscoreToday(statsDict["bestscoreToday"]);
    setDailyTimeData(
      addLists(
        contStatsDict["continuousDailyTimeData"],
        statsDict["perfectionDailyTimeData"]
      )
    );
    // setGraphData({
    //   bestscoreData: landingPageData.bestscoreData,
    //   labels: landingPageData.labels,
    // });
    // console.log("landingpagedata", landingPageData);
    // setDailyTimeData(landingPageData.totaltimeData);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    let contStatsDictPromise = continuousModeUpdate();
    let statsDictPromise = update();
    Promise.all([contStatsDictPromise, statsDictPromise])
      .then((values) => {
        if (Platform.OS !== "web") {
          unstable_batchedUpdates(() => batchUpdate(values));
        } else {
          batchUpdate(values);
        }
      })
      .catch((err) => {
        console.log("error in promise all", err);
      });
  }, [props.bestscoreToday, props.todayString, graph30DayToggle]);

  const handlePress = useLinkPressHandler("/home");

  const countNonzero = (arr: number[]) => {
    return arr.filter((x) => x > 0).length;
  };

  const statsStyle = {
    marginBottom: 200,
    width: "100%",
    maxWidth: 500,
    padding: 10,
    backgroundColor: "white",
    borderRadius: 10,
    shadowRadius: 1,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
  };
  let toDisplay;

  let graphMainDisplay = null;
  const hasContinuousData =
    Math.max(...continuousGraphData["pointsscoreData"]) > 0;
  const hasPerfectionData = Math.max(...graphData["bestscoreData"]) > 0;
  const hasSomeData = hasContinuousData || hasPerfectionData;

  const statsDisplay = (
    <View style={[{ width: "100%" }, styles.container]}>
      <Text style={[styles.h1, { marginTop: 10 }]}>Stats</Text>
      <View elevation={VIEW_ELEVATION} style={statsStyle}>
        {hasPerfectionData ? (
          <View>
            <Text style={{ fontSize: 17, fontWeight: "bold" }}>
              Perfection Mode
            </Text>
            <StatsBlock
              text={"Personal Best:"}
              value={stats["personalBest"]}
              // value={"30m 2s"}
            ></StatsBlock>
            {/* <StatsBlock
          text={"30 day average of best score:"}
          value={stats["bestscore30DayAverage"]}
        ></StatsBlock> */}
            <StatsBlock
              text={"7 day average score:"}
              value={stats["bestscore7DayAverage"]}
              // value={"21m 11s"}
            ></StatsBlock>
          </View>
        ) : null}
        {/* <StatsBlock
          text={"Total Sessions:"}
          value={stats["totaltrials"]}
        ></StatsBlock> */}
        <View>
          <Text
            style={{
              fontSize: 17,
              fontWeight: "bold",
              marginTop: hasPerfectionData ? 10 : 0,
            }}
          >
            Continuous Mode
          </Text>
          <StatsBlock
            text={"Personal Best:"}
            value={continuousStats["personalBest"]}
          ></StatsBlock>
          <StatsBlock
            text={"7 day average score:"}
            value={continuousStats["bestscore7DayAverage"]}
          ></StatsBlock>
        </View>
        <Text style={{ fontSize: 17, fontWeight: "bold", marginTop: 10 }}>
          Time Stats
        </Text>
        <StatsBlock
          text={"Total Practice Days:"}
          value={countNonzero(dailyTimeData)}
        ></StatsBlock>
        <StatsBlock
          text={"Consecutive Practice Days:"}
          value={getConsecutiveDays(dailyTimeData)}
        ></StatsBlock>
      </View>
    </View>
  );

  if (hasSomeData) {
    graphMainDisplay = (
      <ScrollView style={{ width: "100%" }}>
        <View style={[styles.topcontainer, {}]}>
          <View style={[styles.container, {}]}>
            <ButtonSwitch
              leftText="30 Days"
              rightText="All Time"
              isLeft={graph30DayToggle}
              setLeftPress={setGraph30DayToggle}
            />
            {hasPerfectionData ? (
              <View>
                <Text style={[styles.h1, { textAlign: "center" }]}>
                  Perfection Mode
                </Text>
                <Text style={[styles.h2, { textAlign: "center" }]}>
                  Maximum Focus Time
                </Text>
                <Graph
                  ydata={sliceIfNecessary(graphData["bestscoreData"])}
                  labels={sliceIfNecessary(graphData["labels"])}
                  aggMethod="max"
                />
              </View>
            ) : null}
            {hasContinuousData ? (
              <View>
                <Text style={[styles.h1, { textAlign: "center" }]}>
                  Continuous Mode
                </Text>
                <Text style={[styles.h2, { textAlign: "center" }]}>
                  Best Score
                </Text>
                <Graph
                  ydata={sliceIfNecessary(
                    continuousGraphData["pointsscoreData"]
                  )}
                  labels={sliceIfNecessary(continuousGraphData["labels"])}
                  aggMethod="max"
                  noYLabels={true}
                />
                <Text style={[styles.h2, { textAlign: "center" }]}>
                  % Bad Numbers Avoided
                </Text>
                <Graph
                  ydata={sliceIfNecessary(
                    continuousGraphData["ratioscoreData"]
                  )}
                  labels={sliceIfNecessary(continuousGraphData["labels"])}
                  aggMethod="max"
                  noYLabels={true}
                />
              </View>
            ) : null}

            {dailyTimeData.length > 0 ? (
              <Text style={styles.h1}>Daily Practice Time</Text>
            ) : null}
            {dailyTimeData.length > 0 ? (
              <Graph
                ydata={sliceIfNecessary(dailyTimeData)}
                labels={sliceIfNecessary(graphData["labels"])}
                aggMethod="mean"
              />
            ) : null}
            {statsDisplay}
          </View>
        </View>
      </ScrollView>
    );
  }

  if (!hasSomeData || isLoading) {
    toDisplay = (
      <View
        style={[
          styles.topcontainer,
          {
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          },
        ]}
      >
        <View
          elevation={VIEW_ELEVATION}
          style={{
            backgroundColor: "white",
            borderRadius: 10,
            maxWidth: "80%",
            marginBottom: 140,
            shadowRadius: 1,
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.8,
            paddingVertical: 5,
            paddingHorizontal: 18,
          }}
        >
          {isLoading ? (
            // {true ? (
            <Text
              style={{ fontSize: 30, textAlign: "center", marginVertical: 20 }}
            >
              Loading...
            </Text>
          ) : (
            <Text style={{ fontSize: 20, textAlign: "center" }}>
              No data yet. To see the graphs, go to{" "}
              {
                <Icon
                  onPress={handlePress}
                  name="home"
                  size={30}
                  color="black"
                />
              }{" "}
              and try it out!
            </Text>
          )}
        </View>
      </View>
    );
  } else if (props.loginStatus === "paid" || debugMode) {
    toDisplay = graphMainDisplay;
  } else {
    toDisplay = (
      <View
        style={{
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* todo put this back*/}
        {/* <GraphFreeTrialWarning
          loginStatus={props.loginStatus}
          numberActiveDays={numberActiveDays}
          style={{ marginBottom: 15 }}
        ></GraphFreeTrialWarning> */}
        {graphMainDisplay}
      </View>
    );
  }
  return toDisplay;
};

const styles = StyleSheet.create({
  topcontainer: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  container: {
    width: `${graphWidth}%`,
    alignItems: "center",
  },
  h1: {
    fontSize: 32,
    fontWeight: "bold",
  },
  h2: {
    fontSize: 28,
  },
  buttonSwitch: {
    flexDirection: "row",
  },
  pressedSwitch: {
    backgroundColor: SECONDARY_BUTTON_COLOR_PRESSED,
    color: "white",
    borderRadius: 10,
    margin: 10,
  },
  switchText: {
    color: "white",
    padding: 10,
  },
  unpressedSwitch: {
    backgroundColor: SECONDARY_BUTTON_COLOR,
    color: "white",
    margin: 10,
    borderRadius: 10,
    shadowRadius: 1,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
  },
});

export default GraphPage;
