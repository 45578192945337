import { useState, useEffect } from "react";
import React, {
  StyleSheet,
  Text,
  SafeAreaView,
  View,
  Platform,
  StatusBar,
} from "react-native";
import {
  NativeRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-native";
import GraphPage from "./progress_graph";
import { LearnMore } from "./learn_more";
import TestingPage from "./testing_page";
import {
  PresentationComponentProps,
  learnMoreWidth,
  DARKGREEN1,
  Background,
  BACKGROUNDBLUE2,
} from "./constants";
import Settings from "./settings";
import Icon from "react-native-vector-icons/Octicons";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const NavBar = (props: any) => {
  const location = useLocation();

  return (
    <View style={[styles.navContainerBackground, {}]}>
      <View style={[styles.navContainer, {}]}>
        <Link
          to="/home"
          style={
            location.pathname == "/home" || location.pathname == "/"
              ? styles.linkStylesPressed
              : styles.linkStyles
          }
          underlayColor={BACKGROUNDBLUE2}
        >
          <Text style={[styles.linkText]}>
            <Icon name="home" size={36} color="black" />
          </Text>
        </Link>
        <Link
          to="/graph"
          style={
            location.pathname == "/graph"
              ? styles.linkStylesPressed
              : styles.linkStyles
          }
          underlayColor={BACKGROUNDBLUE2}
        >
          <Text style={[styles.linkText]}>
            <Icon name="graph" size={36} color="black" />
          </Text>
        </Link>
        <Link
          to="/learn"
          style={
            location.pathname == "/learn"
              ? styles.linkStylesPressed
              : styles.linkStyles
          }
          underlayColor={BACKGROUNDBLUE2}
        >
          <Text style={[styles.linkText]}>
            <Icon name="question" size={36} color="black" />
          </Text>
        </Link>
        <Link
          to="/settings"
          style={
            location.pathname == "/settings"
              ? styles.linkStylesPressed
              : styles.linkStyles
          }
          underlayColor={BACKGROUNDBLUE2}
        >
          <Text style={[styles.linkText]}>
            <Icon name="gear" size={36} color="black" />
          </Text>
        </Link>
      </View>
    </View>
  );
};

const PresentationComponent = (props: any) => {
  const [email, setEmail] = useState(props.email);
  const [loginStatus, setLoginStatus] = useState(props.loginStatus);
  const [tutorialMode, setTutorialMode] = useState(props.tutorialMode);
  const insets = useSafeAreaInsets();
  const [continuousMode, setContinuousMode] = useState(props.continuousMode);
  const [bestscoreToday, setbestscoreToday] = useState(props.bestscoreToday);

  useEffect(() => {
    setbestscoreToday(props.bestscoreToday);
  }, [props.bestscoreToday]);
  useEffect(() => {
    setLoginStatus(props.loginStatus);
  }, [props.loginStatus]);
  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);
  useEffect(() => {
    setContinuousMode(props.continuousMode);
  }, [props.continuousMode]);

  if (Platform.OS === "web") {
    document.body.onkeydown = (e) => {
      if ((e.key === " " || e.code === "Space") && e.target == document.body) {
        e.preventDefault();
        props.handlePress();
      }
    };
  }
  useEffect(() => {
    setTutorialMode(props.tutorialMode);
  }, [props.tutorialMode]);

  return (
    <NativeRouter>
      <Background customStyles={{ height: "100%" }}>
        <View
          style={[
            styles.container,
            {
              paddingTop: 2,
              // Platform.OS == "android" ? StatusBar.currentHeight + 2 : 2,
            },
          ]}
        >
          <NavBar />
          <Routes>
            {/* <Route path="/" element={<TestingPage {...props}></TestingPage>} /> */}
            <Route
              path="/home"
              element={
                <TestingPage
                  {...props}
                  bestscoreToday={bestscoreToday}
                ></TestingPage>
              }
            />
            <Route
              path="/graph"
              element={
                <GraphPage
                  todayString={props.todayString}
                  bestscoreToday={bestscoreToday}
                  email={email}
                  setEmail={props.setEmail}
                  loginStatus={loginStatus}
                  continuousMode={continuousMode}
                  isTesting={props.isTesting}
                  doneTesting={props.doneTesting}
                  endTesting={props.endTesting}
                />
              }
            />
            <Route path="/" element={<TestingPage {...props}></TestingPage>} />

            {/* <Route path="/" element={<LearnMore />} /> */}
            <Route path="/learn" element={<LearnMore {...props} />} />
            {/* <Route path="/" element={<Settings {...props} />} /> */}
            <Route path="/settings" element={<Settings {...props} />} />
          </Routes>
        </View>
      </Background>
    </NativeRouter>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    // backgroundColor: "darkgreen",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
  },
  linkText: {
    fontSize: 22,
    color: "black",
    fontWeight: "bold",
    textAlign: "center",
    padding: 5,
  },
  testingContainer: {
    flex: 1,
    width: "100%",
    height: "100%",
    flexDirection: "row",
    backgroundColor: "black",
    alignItems: "center",
    justifyContent: "center",
  },
  testingCancel: {
    backgroundColor: "black",
  },
  navContainer: {
    // height: 33,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "90%",
    borderBottomWidth: 1,
    paddingBottom: 5,
    display: "flex",
    // alignItems: "flex-start",
  },
  navContainerBackground: {
    // backgroundColor: "hsla(227, 8%, 78%, 1)",
    // width: "100%",
    // flexDirection: "row",
    // justifyContent: "center",
    paddingTop: 3,
    width: "90%",
    flexDirection: "row",
    justifyContent: "center",
  },
  testingText: {
    // fontSize: 350,
    color: "white",
  },
  testingTopContainer: {
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  doneTestingView: {
    justifyContent: "center",
    alignItems: "center",
  },
  linkStyles: {
    // backgroundColor: "red",
    width: "24%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderRadius: 20,
  },
  linkStylesPressed: {
    backgroundColor: "hsla(190, 80%, 54%, 1)",
    width: "24%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderRadius: 20,
  },

  shadow: {
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.7,
    shadowColor: "hsla(223, 57%, 39%, 1)",
  },
});

const startPageStyles = StyleSheet.create({
  startPage: {
    height: "100%",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  h1: {
    fontSize: 30,
  },
  p: {
    fontSize: 15,
  },
  button: {
    backgroundColor: DARKGREEN1,
    fontSize: 50,
    borderRadius: 10,
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
  },
});

export default PresentationComponent;
// export { PerformanceChart }
