import { getSartValues, nextSartNumber } from "./random_generator";
import { storeData, getData } from "./data_manipulation";
import { getRequest, postRequest } from "./data_manipulation";
import { Alert } from "react-native";

type PerformanceStats = {
  correctThrees: number;
  correctTotal: number;
  totalThrees: number;
  totalVals: number;
  percentThreesCorrect: number;
  date: any;
};

class Model {
  valSequence: number[];
  valDisplayTime: number;
  waitBetweenDisplayTime: number;
  numThrees: number;
  numValues: number;
  pressSequence: number[];
  pastData: PerformanceStats[];
  lastSetTime: number | null;
  serverUrl: string;
  pendingTimeouts: NodeJS.Timeout[];
  suddenDeathPress: boolean;
  debugMode: boolean;
  numTrials: number;
  bestTime: number;
  averageTime: number;
  startTime: number;
  totaltime: number;
  userId: string;
  reactionTimes: number[];
  lastNumberStartTime: number;
  badNumber: number;
  didAlreadyPress: boolean;
  constructor() {
    this.numThrees = 1;
    this.numValues = 20;
    (this.serverUrl = "http://192.168.0.16:3000/api/"),
      (this.valSequence = getSartValues(this.numValues, this.numThrees));
    this.pressSequence = Array(this.numValues).fill(0);
    this.pastData = [];
    this.lastSetTime = null;
    this.pendingTimeouts = [];
    this.suddenDeathPress = false;
    this.debugMode = false;
    this.numTrials = 0;
    this.bestTime = 0;
    this.averageTime = 0;
    this.startTime = 0;
    this.totaltime = 0;
    this.lastNumberStartTime = 0;
    this.reactionTimes = [];
    this.userId = "testUser";
    this.badNumber = 3;
    this.didAlreadyPress = false;
  }

  addNewScore = (totaltime: number) => {
    if (this.numTrials > 0) {
      this.averageTime =
        (this.averageTime * this.numTrials + totaltime) / (this.numTrials + 1);
    } else {
      this.averageTime = totaltime;
    }
    this.numTrials += 1;
    this.totaltime = totaltime;
    this.bestTime = Math.max(this.bestTime, totaltime);
  };

  computeScore = (): PerformanceStats => {
    let correctThrees = 0;
    let correctTotal = 0;
    for (let i = 0; i < this.valSequence.length; i++) {
      if (this.valSequence[i] == 3) {
        correctThrees += 1 - this.pressSequence[i];
        correctTotal += 1 - this.pressSequence[i];
      } else {
        correctTotal += this.pressSequence[i];
      }
    }
    return {
      correctThrees: correctThrees,
      correctTotal: correctTotal,
      totalThrees: this.numThrees,
      totalVals: this.numValues,
      percentThreesCorrect: correctThrees / this.numThrees,
      date: new Date().toString(),
    };
  };

  reset = () => {
    for (let i = 0; i < this.pendingTimeouts.length; i++) {
      clearTimeout(this.pendingTimeouts[i]);
    }
    this.valSequence = getSartValues(this.numValues, this.numThrees);
    this.pressSequence = Array(this.numValues).fill(0);
    this.suddenDeathPress = false;
    // this.totaltime = 0;
    this.pendingTimeouts = [];
    this.reactionTimes = [];
    this.lastNumberStartTime = 0;
  };

  testData = async () => {
    const didStore = await storeData("key", "test");
    const didRetrieve = await getData("key");
  };

  testNode = async () => {
    const response = await postRequest(this.serverUrl + "api/log_data", {
      test: "test",
    });
    if (response == null) {
      Alert.alert("Error", "error saving data");
    }
  };
}

export default Model;
export { PerformanceStats };
