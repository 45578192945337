import AsyncStorage from "@react-native-async-storage/async-storage";
import { supabase } from "./supabase";
import { Session } from "@supabase/supabase-js";
import { getAllUserData, supabaseUpsert } from "./supabase_utils";
import { backupData } from "./backupData";
import { CONTINUOUS_MODE_TIME_THRESHOLD } from "./constants";

const getTodayDateString = () => {
  const today = new Date();
  const dateString = `${
    today.getMonth() + 1
  }-${today.getDate()}-${today.getFullYear()}`;
  return dateString;
};

const getTodayDateStringSQL = () => {
  const today = new Date();
  const dateString = `
  ${today.getFullYear()}-
  ${today.getMonth() + 1}-
  ${today.getDate()}`;
  return dateString;
};

const convertSQLDateStringToDate = (dateString: string) => {
  let dateStringArr = dateString.split("-");
  const returnDate = `
    ${dateStringArr[0]}-
    ${parseInt(dateStringArr[1]) - 1}-
    ${dateStringArr[2]}`;
  return returnDate;
};

const todayDateStringToDate = (dateString: string) => {
  let dateStringArr = dateString.split("-");

  const returnDate = new Date(
    parseInt(dateStringArr[2]),
    parseInt(dateStringArr[0]) - 1,
    parseInt(dateStringArr[1])
  );
  return returnDate;
};

const storeDataByDate = async (toSave: any) => {
  const dateString = getTodayDateString();
  const valueString = await AsyncStorage.getItem(dateString);
  let value;
  if (valueString !== null) {
    value = JSON.parse(valueString);
    value["data"].push(toSave);
  } else {
    value = { data: [toSave] };
    updateAllDates(dateString);
  }
  await AsyncStorage.setItem(dateString, JSON.stringify(value));
};

const updateAllDates = async (dateString: string) => {
  const allDatesString = await AsyncStorage.getItem("allDates");
  if (allDatesString !== null) {
    let allDates = JSON.parse(allDatesString);
    allDates = allDates.filter((date: string) => date !== dateString);
    allDates.push(dateString);
    await AsyncStorage.setItem("allDates", JSON.stringify(allDates));
  } else {
    await AsyncStorage.setItem("allDates", JSON.stringify([dateString]));
  }
};

const updateDataStorageFormat = async () => {
  const allDatesString = await AsyncStorage.getItem("allDates");
  if (allDatesString == null) return;
  for (let date of JSON.parse(allDatesString)) {
    const valueString = await AsyncStorage.getItem(date);
    if (valueString == null) continue;
    const value = JSON.parse(valueString);
    const newData = value["data"].map((val: any) => {
      let newDate;
      try {
        newDate = new Date(val["date"]).toISOString();
      } catch (err) {
        return val;
      }

      return {
        datetime: newDate,
        totaltime: val.hasOwnProperty("totaltime") ? val["totaltime"] : 0,
        reactiontime: val.hasOwnProperty("reactionTime")
          ? val["reactionTime"]
          : 0,
        id: val.hasOwnProperty("id") ? val["id"] : "0",
      };
    });
    value["datav2"] = newData;
    await AsyncStorage.setItem(date, JSON.stringify(value));
  }
};

const updateDataStorageFormatv2 = async () => {
  let allDatesString = await AsyncStorage.getItem("allDates");
  let allDates;
  if (allDatesString == null) {
    allDates = [getTodayDateString()];
  } else {
    allDates = await JSON.parse(allDatesString);
  }
  const startDate = todayDateStringToDate(allDates[0]);
  const yd = todayDateStringToDate(allDates[0]);
  yd.setDate(yd.getDate() - 1);

  const today = getTodayDateString();
  let datesList = [`${yd.getMonth() + 1}-${yd.getDate()}-${yd.getFullYear()}`];
  for (let d = startDate; d <= new Date(); d.setDate(d.getDate() + 1)) {
    datesList.push(`${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`);
  }
  let plotDatesPromise = [];
  for (let date of datesList) {
    const dayDate = await AsyncStorage.getItem(`summary-${date}`);
    if (dayDate == null) continue;
    const dayDateJSON = JSON.parse(dayDate);
    const newData = {
      localdate: dayDateJSON.hasOwnProperty("date")
        ? dayDateJSON["date"]
        : dayDateJSON["localdate"],
      meanscoretoday: dayDateJSON.hasOwnProperty("meanscore")
        ? dayDateJSON["meanscore"]
        : dayDateJSON["meanscoretoday"],
      bestscoretoday: dayDateJSON.hasOwnProperty("bestscore")
        ? dayDateJSON["bestscore"]
        : dayDateJSON["bestscoretoday"],
      worstscoretoday: dayDateJSON.hasOwnProperty("worstscoretoday")
        ? dayDateJSON["worstscoretoday"]
        : 3.164,
      totaltrialstoday: dayDateJSON.hasOwnProperty("totaltrials")
        ? dayDateJSON["totaltrials"]
        : dayDateJSON["totaltrialstoday"],
    };
    await AsyncStorage.setItem(`summary-${date}`, JSON.stringify(newData));
  }
};

const clearAllData = async () => {
  const allDatesString = await AsyncStorage.getItem("allDates");
  if (allDatesString == null) return;
  await Promise.all(
    JSON.parse(allDatesString).map((date: any) => {
      try {
        AsyncStorage.removeItem(`summary-${date}`);
      } catch (err) {}
      try {
        AsyncStorage.removeItem(`summary-continuous-${date}`);
      } catch (err) {}
    })
  );
};

const mergeRowsContinuous = (row1: any, row2: any) => {
  if (row1["localdate"] !== row2["localdate"]) {
    throw new Error("cannot merge rows with different dates");
  }

  const chosenRow = getBestContinuousStats(row1, row2);
  chosenRow["totaltime"] = row1["totaltime"] + row2["totaltime"];
  return chosenRow;
};

const mergeRows = (row1: any, row2: any) => {
  if (row1["localdate"] !== row2["localdate"]) {
    throw new Error("cannot merge rows with different dates");
  }
  const toReturn = {
    localdate: row1["localdate"],
    meanscoretoday:
      (row1["meanscoretoday"] * row1["totaltrialstoday"] +
        row2["meanscoretoday"] * row2["totaltrialstoday"]) /
      (row1["totaltrialstoday"] + row2["totaltrialstoday"]),
    bestscoretoday: Math.max(row1["bestscoretoday"], row2["bestscoretoday"]),
    worstscoretoday: Math.min(row1["worstscoretoday"], row2["worstscoretoday"]),
    totaltrialstoday: row1["totaltrialstoday"] + row2["totaltrialstoday"],
  };
  return toReturn;
};

const mergeSupabaseDataWithLocal = async (
  session: any,
  continuousMode: boolean
) => {
  let pendingUploads = await getData("pendingUploads");
  pendingUploads = pendingUploads
    .filter((row: any) => {
      if (row[1] == continuousMode) {
        return true;
      }
      return false;
    })
    .map((row: any) => row[0]);
  const database = continuousMode ? "continuous_data" : "perfection_data";
  const response = await getAllUserData(session.user.id, database);
  const { data, error } = response;
  if (error) {
    console.log("error getting data", error);
    throw new Error("error getting data");
  }

  const supabaseDateDataMap = mapObjectsByLocalDate(data);
  const pendingUploadsDateDataMap = mapObjectsByLocalDate(pendingUploads);

  const allDates = [];
  const mergedDateDataMap: Record<string, any> = {};

  for (let rowDate in supabaseDateDataMap) {
    //rowDate is the localdate of the row
    const row = supabaseDateDataMap[rowDate];
    allDates.push(rowDate);

    if (rowDate in pendingUploadsDateDataMap) {
      mergedDateDataMap[rowDate] = continuousMode
        ? mergeRowsContinuous(
            supabaseDateDataMap[rowDate],
            pendingUploadsDateDataMap[rowDate]
          )
        : mergeRows(
            supabaseDateDataMap[rowDate],
            pendingUploadsDateDataMap[rowDate]
          );
      delete pendingUploadsDateDataMap[rowDate];
      const toUpload = mergedDateDataMap[rowDate];
      toUpload["id"] = session.user.id;
      const response = await supabaseUpsert(database, toUpload);
      delete toUpload["id"];
      const { data, error } = response;
      if (error) {
        console.log("error updating data", error);
        throw new Error("error updating data");
      }
    } else {
      mergedDateDataMap[rowDate] = row;
    }
  }
  for (let rowDate in pendingUploadsDateDataMap) {
    const row = pendingUploadsDateDataMap[rowDate];
    allDates.push(rowDate);
    mergedDateDataMap[rowDate] = row;
  }

  AsyncStorage.setItem("allDates", JSON.stringify(allDates));
  for (let rowDate in mergedDateDataMap) {
    const keyName = continuousMode
      ? `summary-continuous-${rowDate}`
      : `summary-${rowDate}`;
    await AsyncStorage.setItem(
      keyName,
      JSON.stringify(mergedDateDataMap[rowDate])
    );
  }
  return;
};

const getDataByDate = async (
  useDailySummary: boolean,
  continuousMode: boolean = false
) => {
  let allDatesString = await AsyncStorage.getItem("allDates");
  let allDates;
  if (allDatesString == null) {
    allDates = [getTodayDateString()];
  } else {
    allDates = await JSON.parse(allDatesString);
  }
  if (allDates.length == 0) {
    allDates = [getTodayDateString()];
  }

  const startDate = todayDateStringToDate(allDates[0]);
  const yd = todayDateStringToDate(allDates[0]);
  yd.setDate(yd.getDate() - 1);

  const today = getTodayDateString();
  let datesList = [`${yd.getMonth() + 1}-${yd.getDate()}-${yd.getFullYear()}`];
  for (let d = startDate; d <= new Date(); d.setDate(d.getDate() + 1)) {
    datesList.push(`${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`);
  }
  let plotDatesPromise = [];
  for (let date of datesList) {
    if (useDailySummary) {
      const dateKey = continuousMode
        ? `summary-continuous-${date}`
        : `summary-${date}`;
      plotDatesPromise.push(
        AsyncStorage.getItem(dateKey).then((value) => {
          if (value == null) {
            if (continuousMode) {
              return {
                localdate: date,
                score: 0,
                sessiontime: 0,
              };
            }
            return {
              localdate: date,
              meanscoretoday: 0,
              bestscoretoday: 0,
              totaltrialstoday: 0,
            };
          }
          return JSON.parse(value);
        })
      );
    } else {
      plotDatesPromise.push(getDataSpecificDate(date));
    }
  }
  const toReturn = await Promise.all(plotDatesPromise).catch((err) => {
    console.log(`error getting data: ${err}`);
    return [];
  });
  return toReturn;
};

const saveAggregateDataToLocal = async () => {
  const data = await getDataByDate(false);
  for (let sample of data) {
    AsyncStorage.setItem(`summary-${sample["date"]}`, JSON.stringify(sample));
  }
};

const updateDayData = async () => {
  const date = getTodayDateString();
  const data = await AsyncStorage.getItem(`summary-${date}`);
  const overWriteData = {
    localdate: date,
    meanscoretoday: 0,
    bestscoretoday: 0,
    totaltrialstoday: 0,
    worstscoretoday: 7200,
  };
  // AsyncStorage.setItem(`summary-${date}`, JSON.stringify(overWriteData));
};

const getDataSpecificDate = async (date: string) => {
  const nullReturn = {
    localdate: date,
    meanscoretoday: 0,
    bestscoretoday: 0,
  };
  return AsyncStorage.getItem(date)
    .then((value) => {
      if (value == null) {
        return nullReturn;
      } else {
        const valJSON = JSON.parse(value)["data"];
        const times = valJSON.map((val: any) => {
          if (val.hasOwnProperty("totaltime")) return val["totaltime"];
          if (val.hasOwnProperty("totaltime")) return val["totaltime"];
          return 0;
        });
        return {
          localdate: date,
          meanscoretoday:
            times.reduce((a: number, b: number) => a + b, 0) / times.length,
          bestscoretoday: Math.max(...times),
          totaltrialstoday: times.length,
        };
      }
    })
    .catch((err) => {
      console.log(`error getting data: ${err}`);
      return nullReturn;
    });
};

const storeData = async (key: string, val: any) => {
  try {
    await AsyncStorage.setItem(key, JSON.stringify(val));
  } catch (error) {
    console.log(`error saving data: ${error}`);
  }
};

const getData = async (key: string) => {
  try {
    const value = await AsyncStorage.getItem(key).catch((err) => null);
    if (value !== null) {
      return JSON.parse(value);
    }
    return [];
  } catch (error) {
    console.log(`error retrieving data: ${error}`);
    return [];
  }
};

const postRequest = async (url: string, data: any) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.log("error in fetch", response);
          return null;
        }
      })
      .catch((err) => {
        console.log("error in fetch2", err);
        return null;
      });
    return response;
  } catch (err) {
    console.log(`error posting data: ${err}`);
    return null;
  }
};

const getRequest = async (url: string) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  try {
    const response = await fetch(url, requestOptions)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(`error in fetch: ${err}`);
        return { ok: false };
      });
    if (response.ok) {
      return true;
    }
    return false;
  } catch (err) {
    console.log(`error in get request: ${err}`);
    return false;
  }
};

class Client {
  url: string;
  constructor(url: string) {
    this.url = url;
  }
  async post(path: string, data: any) {
    return await postRequest(this.url + path, data);
  }
  async get(path: string) {
    return await getRequest(this.url + path);
  }
}

const computeContinuousModeScore = (stats: any) => {
  const goodNumberRatio =
    stats.totalGoodNumber > 0
      ? stats.goodNumberPresses / stats.totalGoodNumber
      : 1;
  const badNumberRatio =
    stats.totalBadNumber > 0
      ? 1 - stats.badNumberPresses / stats.totalBadNumber
      : 1;

  const points =
    stats.goodNumberPresses +
    (stats.totalBadNumber - stats.badNumberPresses) -
    (stats.totalGoodNumber - stats.goodNumberPresses + stats.badNumberPresses) *
      2;
  // Math.ceil(
  // (stats.totalGoodNumber + stats.totalBadNumber) * 0.9
  // );

  return {
    ratioscore: Math.min(goodNumberRatio, badNumberRatio),
    pointsscore: points,
  };
};

const continuousModeGetBetterStats = (oldStats: any, newStats: any) => {
  if (
    (oldStats.sessiontime < CONTINUOUS_MODE_TIME_THRESHOLD &&
      newStats.sessiontime >= CONTINUOUS_MODE_TIME_THRESHOLD) ||
    (oldStats.sessiontime >= CONTINUOUS_MODE_TIME_THRESHOLD &&
      newStats.sessiontime < CONTINUOUS_MODE_TIME_THRESHOLD)
  ) {
    return oldStats.sessiontime > newStats.sessiontime ? oldStats : newStats;
  } else {
    return oldStats.score > newStats ? oldStats : newStats;
  }
};

const getUpdatedTodayContinuousData = async (stats: any) => {
  const todayDateString = getTodayDateString();
  let todaySummary = await AsyncStorage.getItem(
    `summary-continuous-${todayDateString}`
  );
  let todaySummaryObj;
  const score = computeContinuousModeScore(stats);
  if (todaySummary == null) {
    return {
      totaltime: stats.totaltime,
      ratioscore: score.ratioscore,
      pointsscore: Math.max(score.pointsscore, 0),
      sessiontime: stats.totaltime,
      localdate: todayDateString,
    };
  } else {
    todaySummaryObj = JSON.parse(todaySummary);
    const newStats = {
      ratioscore: score.ratioscore,
      pointsscore: score.pointsscore,
      sessiontime: stats.totaltime,
    };
    let updatedStats = getBestContinuousStats(todaySummaryObj, newStats);
    updatedStats["totaltime"] = todaySummaryObj.totaltime + stats.totaltime;
    updatedStats["localdate"] = todayDateString;
    updatedStats["ratioscore"] = Math.max(updatedStats["ratioscore"], 0);
    return updatedStats;
  }
};

const getBestContinuousStats = (oldscore, newscore) => {
  if (oldscore.pointsscore == newscore.pointsscore) {
    return oldscore.ratioscore > newscore.ratioscore ? oldscore : newscore;
  }
  return oldscore.pointsscore > newscore.pointsscore ? oldscore : newscore;
};

const getUpdatedTodayAggregateData = async (totaltime: number) => {
  const todayDateString = getTodayDateString();
  let todaySummary = await AsyncStorage.getItem(`summary-${todayDateString}`);
  let todaySummaryObj;
  if (todaySummary == null) {
    todaySummaryObj = {
      localdate: todayDateString,
      meanscoretoday: totaltime,
      bestscoretoday: totaltime,
      worstscoretoday: totaltime,
      totaltrialstoday: 1,
    };
  } else {
    todaySummaryObj = JSON.parse(todaySummary);
    todaySummaryObj["meanscoretoday"] =
      (todaySummaryObj["meanscoretoday"] * todaySummaryObj["totaltrialstoday"] +
        totaltime) /
      (todaySummaryObj["totaltrialstoday"] + 1);
    todaySummaryObj["bestscoretoday"] = Math.max(
      totaltime,
      todaySummaryObj["bestscoretoday"]
    );
    todaySummaryObj["worstscoretoday"] = Math.min(
      totaltime,
      todaySummaryObj["worstscoretoday"]
    );
    todaySummaryObj["totaltrialstoday"] += 1;
  }
  return todaySummaryObj;
};

const saveTodayAggregateData = async (toSave: any) => {
  const todayDateString = getTodayDateString();
  await AsyncStorage.setItem(
    `summary-${todayDateString}`,
    JSON.stringify(toSave)
  );
};

const getUpdatedTodaySummary = async (toSave: any) => {
  const todayDateString = getTodayDateString();
  let todaySummary = await AsyncStorage.getItem(`summary-${todayDateString}`);
  let todaySummaryObj;
  if (todaySummary == null) {
    todaySummaryObj = {
      date: todayDateString,
      meanscore: toSave["totaltime"],
      bestscore: toSave["totaltime"],
      totaltrials: 1,
      syncedToSupabase: false,
    };
  } else {
    todaySummaryObj = JSON.parse(todaySummary);
    todaySummaryObj["meanscore"] =
      (todaySummaryObj["meanscore"] * todaySummaryObj["totaltrials"] +
        toSave["totaltime"]) /
      (todaySummaryObj["totaltrials"] + 1);
    todaySummaryObj["bestscore"] = Math.max(
      toSave["totaltime"],
      todaySummaryObj["bestscore"]
    );
    todaySummaryObj["totaltrials"] += 1;
    todaySummaryObj["syncedToSupabase"] = false;
  }
  return todaySummaryObj;
};

function mapObjectsByLocalDate(objects: any): Record<string, any> {
  const result: Record<string, any> = {};
  for (const obj of objects) {
    result[obj.localdate] = obj;
  }
  return result;
}

const saveSupabaseDataToLocal = async (
  session: any,
  continuousMode: boolean
) => {
  const database = continuousMode ? "continuous_data" : "perfection_data";
  const response = await getAllUserData(session.user.id, database);

  const { data, error } = response;
  if (error) {
    console.log("error getting data", error);
    return;
  }
  let allDates = await getData("allDates");
  for (let row of data) {
    const rowDate = row["localdate"];
    allDates.push(rowDate);
    delete row.id;
    const storageName = continuousMode
      ? `summary-continuous-${rowDate}`
      : `summary-${rowDate}`;
    await AsyncStorage.setItem(storageName, JSON.stringify(row));
  }
  // remove duplicates from alldates
  allDates = [...new Set(allDates)];
  AsyncStorage.setItem("allDates", JSON.stringify(allDates));

  return;
};

const addToPendingUploads = async (toSave: any, continuousMode: boolean) => {
  let pendingUploadsString = await AsyncStorage.getItem("pendingUploads");
  let pendingUploads;
  if (pendingUploadsString == null) {
    pendingUploads = [];
  } else {
    pendingUploads = JSON.parse(pendingUploadsString);
  }
  pendingUploads = pendingUploads.filter((upload: any) => {
    return !(
      upload[0]["localdate"] == toSave["localdate"] &&
      upload[1] == continuousMode
    );
  });
  pendingUploads.push([toSave, continuousMode]);
  AsyncStorage.setItem("pendingUploads", JSON.stringify(pendingUploads));
};

const logSuccessfulSupabaseUpload = async (toSave: any) => {
  let lastSuccessfullUploadString = await AsyncStorage.getItem(
    "lastSuccessfullUpload"
  );
  let lastSuccessfullUpload;
  if (lastSuccessfullUploadString == null) {
    lastSuccessfullUpload = [];
  } else {
    lastSuccessfullUpload = JSON.parse(lastSuccessfullUploadString);
  }
  lastSuccessfullUpload = lastSuccessfullUpload.filter(
    (sample: any) => sample["localdate"] != toSave["localdate"]
  );
  lastSuccessfullUpload.push(toSave);
  AsyncStorage.setItem(
    "lastSuccessfullUpload",
    JSON.stringify(lastSuccessfullUpload)
  );
};

const uploadPendingUploadsToSupabase = async (session: Session) => {
  const pendingUploads = await getData("pendingUploads").catch((err) => []);
  // const pendingUploads = backupData;

  const failedUploads = [];
  for (let toUpload of pendingUploads) {
    let sample = toUpload[0];
    let continuousMode = toUpload[1];
    sample["id"] = session.user.id;
    // don't upload today's data because it was already uploaded
    // if (sample["localdate"] == getTodayDateString()) continue;
    const database = continuousMode ? "continuous_data" : "perfection_data";
    const response = await supabase.from(database).upsert(sample);
    if (response.error) {
      console.log(
        "error uploading pending uploads to supabase: ",
        sample,
        response
      );
      failedUploads.push([sample, continuousMode]);
    } else {
      logSuccessfulSupabaseUpload(sample);
    }
  }
  storeData("pendingUploads", failedUploads);
};

const combinedSaveData = async (
  toSave: any,
  session: Session | null,
  continuousMode: boolean
) => {
  let response;
  // debugger;
  // const todaySummary = await getUpdatedTodaySummary(toSave);
  if (continuousMode) {
    storeData(`summary-continuous-${toSave["localdate"]}`, toSave);
  } else {
    storeData(`summary-${toSave["localdate"]}`, toSave);
  }
  // toSave["continuousMode"] = continuousMode ? 1 : 0;
  if (!session?.user) {
    session = await supabase.auth.getSession();
  }
  if (session?.user) {
    toSave["id"] = session.user.id;
    // debugger;
    if (continuousMode) {
      // try {
      response = await supabase.from("continuous_data").upsert(toSave);
      // .catch((err) => {
      //   console.log("error uploading to supabase combinedSaveData", err);
      //   return "asdf";
      // });
      // } catch (err) {
      // console.log("error uploading to supabase combinedSaveData", err);
      // return "asdf";
      // }
    } else {
      response = await supabase.from("perfection_data").upsert(toSave).select();
    }
  } else {
    console.log("no session user so no upload to supabase");
  }
  let saveFail = !session?.user || response === undefined || response.error;
  saveFail = true;
  if (saveFail) {
    console.log("error saving to supabase", response);
    addToPendingUploads(toSave, continuousMode);
  } else {
    logSuccessfulSupabaseUpload(toSave);
    uploadPendingUploadsToSupabase(session);
  }
  updateAllDates(toSave["localdate"]);
};

// function to take two lists, and return a list with the entries added together. if the lists are different lengths, they should be aligned to the right
const addLists = (list1: any[], list2: any[]) => {
  let returnList = [];
  let index1 = list1.length - 1;
  let index2 = list2.length - 1;
  while (index1 >= 0 && index2 >= 0) {
    returnList.unshift(list1[index1] + list2[index2]);
    index1 -= 1;
    index2 -= 1;
  }
  while (index1 >= 0) {
    returnList.unshift(list1[index1]);
    index1 -= 1;
  }
  while (index2 >= 0) {
    returnList.unshift(list2[index2]);
    index2 -= 1;
  }
  return returnList;
};

export {
  addLists,
  storeData,
  getData,
  postRequest,
  getRequest,
  storeDataByDate,
  getDataByDate,
  getTodayDateString,
  getTodayDateStringSQL,
  combinedSaveData,
  updateDataStorageFormat,
  saveAggregateDataToLocal,
  getUpdatedTodayAggregateData,
  getUpdatedTodayContinuousData,
  saveTodayAggregateData,
  updateDataStorageFormatv2,
  updateDayData,
  uploadPendingUploadsToSupabase,
  Client,
  saveSupabaseDataToLocal,
  clearAllData,
  computeContinuousModeScore,
  addToPendingUploads,
  logSuccessfulSupabaseUpload,
  mapObjectsByLocalDate,
  supabaseUpsert,
  mergeSupabaseDataWithLocal,
};
