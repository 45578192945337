import React, {
  View,
  Text,
  Modal,
  TouchableOpacity,
  StyleSheet,
} from "react-native";

import { useState, useEffect, useRef } from "react";
import {
  VIEW_ELEVATION,
  SECONDARY_BUTTON_COLOR,
  PRIMARY_BUTTON_COLOR,
} from "./constants";

import { default as Octicon } from "react-native-vector-icons/Octicons";
import { useLinkPressHandler } from "react-router-native";

const CongratsPassedBeginnerModal = (props: any) => {
  const [modalVisible, setModalVisible] = useState(
    props.congratsPassedBeginner
  );

  const goToSettings = useLinkPressHandler("/settings");

  useEffect(() => {
    setModalVisible(props.congratsPassedBeginner);
  }, [props.congratsPassedBeginner]);

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        // height: "100%",
        // backgroundColor: "red",
      }}
      onRequestClose={() => {
        props.resetCongratsPassedBeginner();
        setModalVisible(!modalVisible);
      }}
    >
      {/* <Background> */}
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "rgba(0,0,0,0.5)",
        }}
        activeOpacity={1}
        // style={{ backgroundColor: "blue" }}
        onPress={() => {
          props.resetCongratsPassedBeginner();
          setModalVisible(false);
        }}
      >
        {/* <View
          style={{
            width: "100%",
            height: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "rgba(0,0,0,0.5)",
          }}
        > */}
        <View
          elevation={VIEW_ELEVATION}
          style={[
            {
              backgroundColor: "white",
              // width: "90%",
              maxWidth: "90%",
              height: "60%",
              borderRadius: 20,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 40,
              shadowRadius: 2,
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.8,
            },
          ]}
        >
          <Octicon
            name="x"
            size={28}
            onPress={() => {
              setModalVisible(false);
              props.resetCongratsPassedBeginner();
            }}
            style={{
              position: "absolute",
              left: 15,
              right: 0,
              top: 10,
              bottom: 0,
            }}
          />
          <Text style={{ textAlign: "center", fontSize: 64 }}>
            Congrats! 🎉
          </Text>
          <Text style={{ textAlign: "center", fontSize: 36, marginTop: 20 }}>
            You passed beginner mode!
          </Text>
          <Text style={{ textAlign: "center", fontSize: 24, marginTop: 20 }}>
            The game will be faster now. If you want to slow it down again, you
            can set Beginner Mode in Settings{" "}
            <Octicon
              name="gear"
              color="black"
              size={24}
              onPress={goToSettings}
            ></Octicon>
          </Text>
        </View>
        {/* </View> */}
      </TouchableOpacity>
      {/* </Background> */}
    </Modal>
  );
};

export default CongratsPassedBeginnerModal;
