import React, {
  View,
  StyleSheet,
  Text,
  SafeAreaView,
  Platform,
  TouchableHighlight,
  Pressable,
  Modal,
  TouchableOpacity,
} from "react-native";
import { choose, randomDifferentNumber } from "./random_generator";
import {
  // Button,
  lightColors,
  createTheme,
  ThemeProvider,
} from "@rneui/themed";
import { useState, useEffect } from "react";
import { VIEW_ELEVATION, learnMoreWidth } from "./constants";

const Tips = (props: any) => {
  const tips = [
    //1
    "This is not easy. Keep practicing and you will get better",
    //2
    "If you feel frustrated, take a step back and treat the frustration as another distraction you can ignore",
    //3
    "Don't focus too hard on the numbers. Instead, think about what you are paying attention to, so that you realize when you get distracted",
    //4
    "Smile while you practice. It'll make you happier and more relaxed",
    //5
    "Try checking in with yourself every time you see the number 5 to check if you are starting to get distracted",
    //6
    "If you're having a hard time focusing, it could be because you didn't get enough sleep, or you've had a stressful day. Give yourself some slack 😃",
    //7
    "When practicing try to be aware of your surroundings and thoughts. This will help you be more present and better focused",
    //8
    "Try closing your eyes, and only listening to the sounds. This is more relaxing.",
    //9
    "Make sure to practice every day, even if it's just for a few minutes. This will help you build the habit of focusing",
    //10
    "Try practicing at different times. It may be more difficult after a big meal, or right before bed",
    //11
    "When you catch yourself getting distracted, be proud of yourself that you caught it. This will help you build the habit of noticing when you get distracted",
  ];

  const [randomState, setRandomState] = useState(props.randomState);
  const [tipIndex, setTipIndex] = useState(-1);
  // todo figure out why tip index always resets to 0
  // I don't think I'm understanding state right

  useEffect(() => {
    // todo why is this running twice, even though I
    // said it should only run when random state updates
    setRandomState(props.randomState);

    if (Math.random() < 0.2) {
      const range = Array.from(Array(tips.length), (x, i) => i);
      const options = range.filter((n: number) => n != tipIndex);
      const chosenIndex = choose(options);
      // const chosenIndex = 3;

      setTipIndex(chosenIndex);
    }
  }, [props.randomState]);

  return tipIndex >= 0 ? (
    <View
      elevation={VIEW_ELEVATION}
      style={{
        width: learnMoreWidth * 0.9,
        backgroundColor: "white",
        borderRadius: 10,
        padding: 10,
        marginBottom: 20,
        shadowRadius: 1,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.7,
      }}
    >
      <Text style={{ fontSize: 16 }}>Tip: {tips[tipIndex]}</Text>
    </View>
  ) : null;
};

export default Tips;
