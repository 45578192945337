import React, { useState, useEffect } from "react";
import { BarChart, LineChart } from "react-native-chart-kit";
import { View, Text, Dimensions } from "react-native";
import { VIEW_ELEVATION } from "./constants";
const screenWidth = Dimensions.get("window").width;
import { graphWidth } from "./constants";
// import landingPageData from "./landing_page_graph_data";

const mdyToDate = (mdy: string[]): Date => {
  return new Date(parseInt(mdy[2]), parseInt(mdy[0]) - 1, parseInt(mdy[1]));
};

const selectLabelsSimple = (labels: string[]): string[] => {
  const targetSize = 6;
  const modulus = Math.ceil(labels.length / targetSize);
  const lastDayModulus = (labels.length - 1) % modulus;

  return labels
    .map((x: string) => {
      const [month, day, year] = x.split("-");
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
    })
    .map((x: string, i: number) => {
      if (i == 0 && lastDayModulus / modulus >= 0.8) return x;
      return i % modulus == lastDayModulus ? x : "";
    });
};

const selectLabels = (labels: string[]): string[] => {
  const labelsParsed = labels.map((x: string) => {
    const [month, day, year] = x.split("-");
    return [month, day, year];
  });
  let returnLabels: string[] = [];
  if (true) {
    returnLabels = selectLabelsSimple(labels);
    // returnLabels = labelsParsed.map((mdy: string[]) => {
    //   if (mdy[1] == "1") {
    //     return mdyToDate(mdy).toLocaleDateString("en-US", {
    //       month: "short",
    //       day: "numeric",
    //     });
    //   } else if (["7", "14", "21"].includes(mdy[1])) {
    //     return mdyToDate(mdy).toLocaleDateString("en-US", {
    //       month: "short",
    //       day: "numeric",
    //     });
    //   } else {
    //     return "";
    //   }
    // });
    // } else if (labels.length < 120) {
    // returnLabels = labelsParsed.map((mdy: string[]) => {
    // if (["1", "15"].includes(mdy[1])) {
    // return mdyToDate(mdy).toLocaleDateString("en-US", {
    // month: "short",
    // day: "numeric",
    // });
    // } else {
    // return "";
    // }
    // });
    // } else {
    // returnLabels = labelsParsed.map((mdy: string[]) => {
    // if (mdy[1] == "1") {
    // return mdyToDate(mdy).toLocaleDateString("en-US", {
    // month: "short",
    // day: "numeric",
    // });
    // } else {
    // return "";
    // }
    // });
    // todo: if there are a lot of months, logic to only show every other or something like that
  }
  return returnLabels;
};

const sum = (arr: number[]) => {
  return arr.reduce((a, b) => a + b, 0);
};

const condenseData = (
  labels: string[],
  data: number[],
  aggMethod: string
): any => {
  const targetSize = 30;
  if (labels.length <= targetSize) {
    return [labels, data];
  }
  let index = 0;
  let blockIndex = 1;
  const outputLabels = [];
  const outputData = [];
  while (index < labels.length - 1) {
    const currentGroup = [];
    let currentLabel = "";
    while (
      index < blockIndex * (labels.length / targetSize) &&
      index < labels.length - 1
    ) {
      if (labels[index] !== "") {
        currentLabel = labels[index];
      }
      currentGroup.push(data[index]);
      index++;
    }
    outputLabels.push(currentLabel);
    if (aggMethod === "min") {
      outputData.push(Math.min(...currentGroup));
    } else if (aggMethod === "max") {
      outputData.push(Math.max(...currentGroup));
    } else {
      outputData.push(sum(currentGroup) / currentGroup.length);
    }
    blockIndex += 1;
  }
  outputLabels.push(labels[labels.length - 1]);
  outputData.push(data[data.length - 1]);
  // outputData[0] = 0;
  return [outputLabels, outputData];
};

const Graph = (props: any) => {
  const [units, setUnits] = useState("s");
  const [labels, setLabels] = useState<string[]>(props.labels);
  const [lastNDays, setLastNDays] = useState(30);
  const [data, setData] = useState<any>({
    labels: props.labels,
    datasets: [
      {
        data: props.ydata,
      },
    ],
  });

  const screenWidth = Dimensions.get("window").width;
  const axesSvg = { fontSize: 10, fill: "grey" };
  const verticalContentInset = { top: 10, bottom: 10 };
  const xAxisHeight = 30;

  useEffect(() => {
    const maxVal = Math.max(...props.ydata);
    let newYData;
    if (props.noYLabels) {
      setUnits("");
      newYData = props.ydata;
    } else {
      if (maxVal < 60) {
        setUnits(" sec");
        newYData = props.ydata;
      } else if (maxVal < 3600) {
        setUnits(" min");
        newYData = props.ydata.map((x: number) => x / 60);
      } else if (maxVal < 3600 * 24) {
        setUnits(" h");
        newYData = props.ydata.map((x: number) => x / 3600);
      } else {
        setUnits(" d");
        newYData = props.ydata.map((x: number) => x / 3600 / 24);
      }
    }

    setLabels(props.labels);

    let newLabels = selectLabels(props.labels);
    [newLabels, newYData] = condenseData(newLabels, newYData, props.aggMethod);
    // these hacks are for the x axis to not be too far to the right
    if (newLabels.length >= 24) {
      newLabels = newLabels.concat(["", "", ""]);
    } else if (newLabels.length >= 17) {
      newLabels = newLabels.concat(["", ""]);
    } else if (newLabels.length >= 9) {
      newLabels = newLabels.concat([""]);
    }
    setData({
      labels: newLabels, //.concat(["", ""]),
      datasets: [
        {
          data: newYData,
        },
      ],
    });
  }, [props.ydata, props.labels]);
  const width = Math.min((screenWidth * graphWidth) / 100, 700);

  if (Math.max(...props.ydata) == 0) {
    return (
      <View
        elevation={VIEW_ELEVATION}
        style={{
          backgroundColor: "white",
          borderRadius: 10,
          marginBottom: 15,
          shadowRadius: 1,
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.8,
        }}
      >
        <Text style={{ textAlign: "center", margin: 5 }}>
          {" "}
          No data in this time range
        </Text>
      </View>
    );
  }

  return labels.length == 0 || isNaN(data.datasets[0].data[0]) ? (
    // todo: clean this up
    <View>
      <Text>Loading.......</Text>
    </View>
  ) : (
    <View
      elevation={VIEW_ELEVATION}
      style={{
        backgroundColor: "white",
        borderRadius: 10,
        marginBottom: 15,
        shadowRadius: 1,
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.8,
      }}
    >
      <LineChart
        data={data}
        fromZero={true}
        verticalLabelRotation={30}
        width={width}
        height={350}
        yAxisLabel=""
        yAxisSuffix={units}
        yAxisInterval={1} // optional, defaults to 1
        withVerticalLines={false}
        showBarTops={false}
        chartConfig={{
          // backgroundGradientFrom: DARKGREEN1,
          // backgroundGradientTo: DARKGREEN1,
          // fillShadowGradientFrom: "hsla(190, 80%, 64%, 1)", // THIS
          // fillShadowGradientTo: "hsla(190, 80%, 63%, 1)", // THIS
          // fillShadowGradientOpacity: 0.6,
          backgroundGradientFrom: "white",
          backgroundGradientTo: "white",
          decimalPlaces: 1, // optional, defaults to 2dp
          color: (opacity = 1) => `hsla(217, 87%, 40%, ${opacity})`,
          labelColor: (opacity = 1) => `hsla(217, 87%, 0%, ${opacity})`,
          style: {
            borderRadius: 16, //pretty sure this doesn't do anything
            // paddingRight: 10,
            height: 300,
          },
          propsForDots: {
            r: "3",
            strokeWidth: "2",
            // stroke: "#ffa726",
          },
          propsForLabels: {
            // fontSize: 10,
            fill: "white",
            dy: 3,
          },
        }}
        bezier
        // withOuterLines={false}
        style={{
          // marginVertical: 8,
          borderRadius: 10,
          // marginRight: -10,
          // paddingRight: 10,
          marginBottom: 0,
          paddingLeft: 0,
        }}
      />
    </View>
  );
};

export default Graph;
export { sum };
