import { Component, useEffect } from "react";
import React, {
  View,
  StyleSheet,
  Text,
  SectionList,
  Platform,
} from "react-native";
import { learnMoreWidth, VIEW_ELEVATION } from "./constants";
import Icon from "react-native-vector-icons/Octicons";
import { useLinkPressHandler } from "react-router-native";

const LearnMore = (props: any) => {
  const goToSettings = useLinkPressHandler("/settings");
  const goToHome = useLinkPressHandler("/home");
  useEffect(() => {
    if (props.isTesting && !props.doneTesting) {
      goToHome();
      props.endTesting();
    }
  }, [props.isTesting, props.doneTesting]);

  const instructions = [
    {
      text: "On the start page, you will see a number you are not supposed to press. When you press start, you will see a series of numbers. Press every number except for this number that you're not supposed to press. That's it! It sounds simple, but it's not easy.",
    },
    {
      text: (
        <Text>
          {
            "There are two speeds, Beginner and Normal. Beginner is slower, and gives you hints. Normal is faster than beginner mode. The tests do not get faster; this is a test of mental endurance and maintaining focus, not reaction time. You can set normal/beginner mode in Settings"
          }{" "}
          <Icon
            onPress={goToSettings}
            name="gear"
            size={16}
            color="black"
          ></Icon>
        </Text>
      ),
    },
    {
      text: (
        <Text>
          {`There are two play modes, Continuous and Perfection. In Continuous mode, you get 1 point for each correct number, and lose 2 points for each mistake. Continuous Mode will keep going until you press \"End\". Perfection mode stops as soon as you make a mistake. You can set Continuous/Perfection mode in Settings `}
          <Icon
            onPress={goToSettings}
            name="gear"
            size={16}
            color="black"
          ></Icon>
        </Text>
      ),
    },
    // {
    //   text:
    //     "Before pressing start, the app warns you about a number that you " +
    //     `shouldn't ${PRESSCLICK}. Then it will flash numbers on the screen. ` +
    //     `Don't ${PRESSCLICK} that number, but ${PRESSCLICK} everything else. The game ends ` +
    //     `as soon as you ${PRESSCLICK} the no-no number, or miss ${PRESSCLICK}ing of of the other ` +
    //     "numbers",
    // },
    // {
    //   text:
    //     "Every 5 minutes, the no-no number will change. After this, you should " +
    //     `${PRESSCLICK} the previous no-no number and not ${PRESSCLICK} the new one`,
    // },
    // {
    //   text:
    //     "This game measures how long you can focus. It's lot more " +
    //     "difficult than you think!",
    // },
  ];

  const howItWorks = [
    {
      text: "Your brain will naturally get bored seeing the numbers over and over again. Then your mind starts to wander, and at that point you lose focus and make a mistake. BrainLaser measures your ability to focus and be mindful, by measuring how long you can focus without making a mistake.",
    },
    {
      text: "This is based off the Sustained Attention Response Task (SART), a test used by brain researchers to measure people's ability to focus. BrainLaser is a modification of the SART test, designed to be more fun and engaging, and to teach you how to improve your focus over time.",
    },
  ];

  const tips = [
    {
      text:
        "If you try to focus really hard, that will work for a while, " +
        "but it won't help you improve over time. To improve, you need to " +
        "learn to realize when your mind wanders, and then refocus. " +
        "When focusing, try to be aware of when your mind wanders. If you can catch " +
        "yourself before you make a mistake, good job! Try to let go of what you " +
        "were thinking about, and re-focus on the game.",
    },
    {
      text:
        "This process of being aware of your mind wandering and refocusing " +
        "is called mindfulness. As you practice, you will get better at it. " +
        "Scientists have shown that mindfulness can improve your focus, reduce stress " +
        "and anxiety, and improve your memory. Brain Researchers recommend at least 12 minutes " +
        "of mindfulness practice per day, but if you practice more you'll see even more improvement.",
    },
    {
      text:
        "This is much more difficult than you expect! If you get frustrated, " +
        "treat your frustration as as another distraction to your focus. Try to " +
        "let it go and re-focus on the game. This is easier said than done, but " +
        "you'll get better at it with practice.",
    },
    {
      text:
        "Try enabling sound (in Settings), then closing your eyes while you play the game. " +
        "This is a more relaxing experience.",
    },
    // {
    //   text:
    //     "If you follow the suggestions in these tips, using this app is helping " +
    //     "you practice mindfulness techniques. Brain researchers recommend at least 12 " +
    //     "minutes practicing mindfulness per day, but the more you practice, the more improvement " +
    //     "you'll see",
    // },
  ];
  if (Platform.OS !== "web") {
    tips.push({
      text:
        "If notifications from your phone are interrupting your focus, " +
        "try turning them off, or setting your phone to Do Not Distub mode " +
        "(to mute all notifications) or silent mode (to allow notifications, but " +
        "turn off sounds). ",
    });
  }

  const advancedTips = [
    {
      text:
        "While focusing on the game, try to be aware of your breath. " +
        "Try to make your breaths long and slow. " +
        "This will help you relax and focus.",
    },
    {
      text:
        "If you start to get sleepy, this could be a sign that you're " +
        "not getting enough sleep. Try to get more sleep, and see if that helps.",
    },
    {
      text: "Your BrainLaser score is representative of your overall mental state. If you're getting worse, that could be a sign you're overly stressed or burnt out.",
    },
  ];

  return (
    // <View style={styles.scrollcontainer}>
    <View style={styles.topcontainer}>
      <View style={styles.container}>
        <SectionList
          contentContainerStyle={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          style={{
            marginBottom: 100,
            width: "100%",
          }}
          sections={[
            { title: "Instructions", data: instructions },
            { title: "How it Works", data: howItWorks },
            { title: "Tips", data: tips },
            { title: "Advanced Tips", data: advancedTips },
          ]}
          renderItem={({ item }) => {
            return (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: learnMoreWidth,
                }}
              >
                <View
                  elevation={VIEW_ELEVATION}
                  style={[
                    styles.shadow,
                    {
                      backgroundColor: "white",
                      borderRadius: 10,
                      marginVertical: 8,
                      width: "98%",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.listitem,
                      {
                        marginHorizontal: 10,
                        marginVertical: 10,
                      },
                    ]}
                  >
                    {item.text}
                  </Text>
                </View>
              </View>
            );
          }}
          renderSectionHeader={({ section: { title } }) => (
            <Text style={styles.h1}>{title}</Text>
          )}
          stickySectionHeadersEnabled={false}
        />
      </View>
    </View>
    // </View>
  );
};

const styles = StyleSheet.create({
  scrollcontainer: {
    width: "100%",
  },
  shadow: {
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
  },
  topcontainer: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    height: "auto",
  },
  container: {
    // maxWidth: learnMoreWidth,
    width: "100%",
    overflow: "hidden",
    height: "auto",
  },
  listitem: {
    fontSize: Platform.OS === "web" ? 14 : 18,
  },
  listContainer: {
    backgroundColor: "red",
    height: "auto",
  },
  text: {
    color: "white",
  },
  h1: {
    fontSize: 30,
    textAlign: "center",
  },
});

export { LearnMore };
