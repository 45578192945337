import React, {
  View,
  StyleSheet,
  Text,
  SafeAreaView,
  Platform,
  TouchableHighlight,
  Pressable,
  Modal,
  TouchableOpacity,
} from "react-native";
import { choose, randomDifferentNumber } from "./random_generator";
import {
  // Button,
  lightColors,
  createTheme,
  ThemeProvider,
} from "@rneui/themed";
import { useState, useEffect, useRef } from "react";
import {
  handleFunc,
  learnMoreWidth,
  PresentationComponentProps,
  VAL_DISPLAY_TIME,
  WAIT_BETWEEN_DISPLAY_TIME,
  learnMoreWidthPercent,
  PRESSCLICK,
  SECONDARY_BUTTON_COLOR,
  BUTTON_ELEVATION,
  VIEW_ELEVATION,
  PRIMARY_BUTTON_COLOR,
  Background,
  TOO_MANY_SECONDS,
} from "./constants";
import Clock from "./clock";
import { sleep, numberSoundMap } from "./constants";
import {
  numberInstructionsAudioFileMap,
  numberAudioFileMap,
  soundEffect,
} from "./audio";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import ButtonSwitch from "./button_switch";
import { default as Octicon } from "react-native-vector-icons/Octicons";
import Icon from "react-native-vector-icons/Entypo";
import { useLinkPressHandler } from "react-router-native";
import { computeContinuousModeScore } from "./data_manipulation";
import Tips from "./tips";
import CongratsPassedBeginnerModal from "./congratsPassedBeginnerModal";
import ShowHelpModal from "./showHelpModal";

const TestingPage = (props: any) => {
  let mainDisplay;
  const [startTime, setStartTime] = useState(props.startTime);
  const [totaltime, settotaltime] = useState(0);
  const [didCancel, setDidCancel] = useState(false);
  const [pageType, setPagetype] = useState("start");
  const [audioFiles, setAudioFiles] = useState<any>({});
  const [instructionsAudioFiles, setInstructionsAudioFiles] = useState<any>({});
  const [didLoad, setDidLoad] = useState(false);
  const [newGoalAudio, setNewGoalAudio] = useState<any>({});
  const [tutorialMode, setTutorialMode] = useState(false);
  const [slowMode, setSlowMode] = useState(props.slowMode);
  const [continuousMode, setContinuousMode] = useState(props.continuousMode);
  const [bestscoreToday, setbestscoreToday] = useState(props.bestscoreToday);
  const [meanscoreToday, setmeanscoreToday] = useState(props.meanscoreToday);
  const [showHelp, setShowHelp] = useState(props.showHelp);

  if (props.totalTime > TOO_MANY_SECONDS) {
    //on android, sometimes display page shows 459K hours. This is a hack to reset that
    //this should be fixed, but this is just here as a backup
    props.handleReset();
  }

  useEffect(() => {
    setbestscoreToday(props.bestscoreToday);
  }, [props.bestscoreToday]);

  useEffect(() => {
    setmeanscoreToday(props.meanscoreToday);
  }, [props.meanscoreToday]);

  useEffect(() => {
    setAudioFiles(numberAudioFileMap);
    setInstructionsAudioFiles(numberInstructionsAudioFileMap);
    setNewGoalAudio(new soundEffect(require("../assets/sounds/new_goal.mp3")));
    setDidLoad(true);
  }, [didLoad]);

  useEffect(() => {
    setStartTime(props.startTime);
  }, [props.startTime]);
  useEffect(() => {
    let toSetPageType = "start";
    if (props.isTesting) {
      toSetPageType = "testing";
    }
    if (props.doneTesting) {
      toSetPageType = "done";
    }
    setPagetype(toSetPageType);
  }, [props.isTesting, props.doneTesting]);
  useEffect(() => {
    setShowHelp(props.showHelp);
  }, [props.showHelp]);
  useEffect(() => {
    if (
      typeof props.totaltime === "undefined" ||
      props.totaltime > TOO_MANY_SECONDS
    ) {
      settotaltime(0);
    } else {
      settotaltime(props.totaltime);
    }
  }, [props.totaltime]);
  useEffect(() => {
    setDidCancel(didCancel);
  }, [props.didCancel]);
  useEffect(() => {
    setTutorialMode(props.tutorialMode);
  }, [props.tutorialMode]);
  useEffect(() => {
    setSlowMode(props.slowMode);
  }, [props.slowMode]);
  useEffect(() => {
    setContinuousMode(props.continuousMode);
  }, [props.continuousMode]);

  const clock = (
    <Clock startTime={startTime} stoppedTime={totaltime} pageType={pageType} />
  );
  // if (true) {
  if (props.isTesting) {
    // if (true) {
    // if (false) {
    if (props.doneTesting) {
      mainDisplay = (
        <DoneTesting
          handleReset={props.handleReset}
          totaltime={props.totaltime}
          reactionTime={props.reactionTime}
          style={styles.doneTestingView}
          bestscoreToday={bestscoreToday}
          worstscoreToday={props.worstscoreToday}
          meanscoreToday={meanscoreToday}
          totaltrialsToday={props.totaltrialsToday}
          clock={clock}
          failureMessage={props.failureMessage}
          continuousMode={props.continuousMode}
          numberStats={props.numberStats}
          congratsPassedBeginner={props.congratsPassedBeginner}
          resetCongratsPassedBeginner={props.resetCongratsPassedBeginner}
          showHelp={showHelp}
          badNumber={props.badNumber}
        />
      );
      // } else if (true) {
    } else if (props.currentlyUpdating) {
      mainDisplay = (
        <UpdatingPage
          {...props}
          instructionsAudioFiles={instructionsAudioFiles}
          newGoalAudio={newGoalAudio}
        />
      );
    } else {
      mainDisplay = (
        <TestingDisplay {...props} clock={clock} audioFiles={audioFiles} />
      );
    }
  } else if (tutorialMode) {
    mainDisplay = (
      <TutorialPage
        {...props}
        setTutorialMode={props.setTutorialMode}
        instructionsAudioFiles={instructionsAudioFiles}
        newGoalAudio={newGoalAudio}
        tutorialMode={props.tutorialMode}
      ></TutorialPage>
    );
  } else {
    mainDisplay = (
      <StartPage
        handleStart={props.handleStart}
        badNumber={props.badNumber}
        toggleBadNumber={props.toggleBadNumber}
        updateBadNumber={props.updateBadNumber}
        useAudio={props.useAudio}
        setTutorialMode={props.setTutorialMode}
        tutorialMode={props.tutorialMode}
        continuousMode={props.continuousMode}
      />
    );
  }
  return (
    <View
      style={{
        // flexBasis: "auto",
        // flexGrow: 1,
        flex: 1,
        // height: 2000,
        width: "100%",
        // height: "100%",
      }}
    >
      <SafeAreaView
        style={{
          width: "100%",
          // height: "100%",
          // height: 2000,
          flex: 1,
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {mainDisplay}
      </SafeAreaView>
    </View>
  );
};

const TutorialPage = (props: any) => {
  if (!props.tutorialMode) {
    return null;
  }
  const tutorialStageText: any = {
    "0": "Welcome to the tutorial! Press the start button to begin the game in tutorial mode. Make sure to pay attention to instructions about which number to not press.",
    "1": `You will see a series of numbers. If the number isn't the number you're not supposed to press (in this case ${props.badNumber}) then press it.`,
    "2": `The tutorial is slowed down, but during the real game you'll have to press it within ${
      (VAL_DISPLAY_TIME + WAIT_BETWEEN_DISPLAY_TIME) / 1000
    } seconds.`,
    "3": `If the the game displays the number you're not supposed to press, then don't press it (Don't press the ${props.badNumber}). Just wait for the next number.`,
    "4": "If you press the number you're not supposed to press, then the game ends, and displays statistics about your performance. Press reset to continue the tutorial",
    "5": "If you have audio mode enabled (you can toggle it in the Settings), then you'll hear a sound for each number. Press start to continue",
    "6": "With audio mode on, each number will be accompanied by audio. We recommend closing your eyes and listening to the sounds. Press the number to continue",
    "7": "Every 5 minutes, the number that you are not supposed to press will change",
    "8": 'You can also go to "Graphs" to see your performance over time, or "Learn More" for tips.',
    "9": "That's it! You're ready to start the game.",
  };

  const [startPageTutorialMode, setStartPageTutorialMode] = useState(
    props.tutorialMode
  );
  const firstTutorialNumber = randomDifferentNumber(props.badNumber);
  const secondTutorialNumber = choose(
    [1, 2, 3, 4, 5, 6, 7, 8, 9].filter(
      (n: number) => n != firstTutorialNumber && n != props.badNumber
    )
  );
  const thirdTutorialNumber = randomDifferentNumber(props.badNumber);

  const [tutorialStage, setTutorialStage] = useState(0);

  const handleExitTutorial = () => {
    props.setTutorialMode(false);
    setStartPageTutorialMode(false);
  };

  let tutorialMainDisplay;
  switch (tutorialStage) {
    case 0:
      tutorialMainDisplay = (
        <StartPage
          tutorialMode={props.tutorialMode}
          handleStart={() => setTutorialStage(tutorialStage + 1)}
          badNumber={props.badNumber}
          toggleBadNumber={props.toggleBadNumber}
          updateBadNumber={props.updateBadNumber}
          useAudio={false}
          setTutorialMode={() => {
            setStartPageTutorialMode(true);
            props.setTutorialMode(true);
            props.handleStart();
          }}
        />
      );
      break;
    case 1:
      tutorialMainDisplay = (
        <TestingDisplay
          {...props}
          toDisplay={firstTutorialNumber}
          useAudio={false}
          clock={null}
          audioFiles={numberAudioFileMap}
          handlePress={() => setTutorialStage(tutorialStage + 1)}
          handleCancel={handleExitTutorial}
        />
      );
      break;
    case 2:
      tutorialMainDisplay = (
        <TestingDisplay
          {...props}
          toDisplay={secondTutorialNumber}
          useAudio={false}
          clock={null}
          audioFiles={numberAudioFileMap}
          handlePress={() => setTutorialStage(tutorialStage + 1)}
          handleCancel={handleExitTutorial}
        />
      );
      break;
    case 3:
      tutorialMainDisplay = (
        <TestingDisplay
          {...props}
          toDisplay={props.badNumber}
          useAudio={false}
          clock={null}
          audioFiles={numberAudioFileMap}
          handlePress={() => setTutorialStage(tutorialStage + 1)}
          handleCancel={handleExitTutorial}
        />
      );
      break;
    case 4:
      tutorialMainDisplay = (
        <DoneTesting
          {...props}
          handleReset={() => setTutorialStage(tutorialStage + 1)}
          handleCancel={() => setTutorialStage(tutorialStage + 1)}
          bestscoreToday={10}
          worstscoreToday={10}
          meanscoreToday={10}
          totaltrialsToday={1}
          clock={null}
        />
      );
      break;
    case 5:
      tutorialMainDisplay = (
        <StartPage
          tutorialMode={false}
          handleStart={() => setTutorialStage(tutorialStage + 1)}
          badNumber={props.badNumber}
          toggleBadNumber={props.toggleBadNumber}
          updateBadNumber={props.updateBadNumber}
          useAudio={true}
          setTutorialMode={() => setStartPageTutorialMode(true)}
        />
      );
      break;
    case 6:
      tutorialMainDisplay = (
        <TestingDisplay
          {...props}
          toDisplay={thirdTutorialNumber}
          useAudio={false}
          clock={null}
          audioFiles={numberAudioFileMap}
          handlePress={() => setTutorialStage(tutorialStage + 1)}
          handleCancel={handleExitTutorial}
        />
      );
      break;
    case 7:
      tutorialMainDisplay = (
        <UpdatingPage
          {...props}
          instructionsAudioFiles={props.instructionsAudioFiles}
          newGoalAudio={props.newGoalAudio}
        />
      );
      break;
    case 8:
      tutorialMainDisplay = (
        <TestingDisplay
          {...props}
          toDisplay={props.badNumber}
          useAudio={false}
          clock={null}
          audioFiles={numberAudioFileMap}
          handlePress={() => setTutorialStage(tutorialStage + 1)}
          handleCancel={handleExitTutorial}
        />
      );
      break;
    default:
      tutorialMainDisplay = null;
  }

  let tutorialText = tutorialStageText[tutorialStage.toString()];
  // if (startPageTutorialMode && tutorialStage === 0) {
  // tutorialText =
  // ' You\'re already in tutorial mode, please press "Start" to continue';
  // }")

  return (
    <View
      style={{
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        width: "100%",
      }}
    >
      <View
        style={{
          display: "flex",
          borderBottomWidth: 1,
          justifyContent: "space-between",
          width: `${learnMoreWidthPercent}%`,
          // width: "95%",
          height: "20%",
          marginTop: 10,
        }}
      >
        <View
          elevation={VIEW_ELEVATION}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View
            style={[
              startPageStyles.shadow,
              {
                backgroundColor: "white",
                borderRadius: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "95%",
              },
            ]}
          >
            <View style={{ width: "95%", marginVertical: 10 }}>
              <Text>{tutorialText}</Text>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-around",
            marginBottom: 10,
            paddingBottom: 10,
          }}
        >
          <Pressable
            elevation={BUTTON_ELEVATION}
            onPress={() => handleExitTutorial()}
            style={[
              startPageStyles.button,
              startPageStyles.shadow,
              { marginTop: 10 },
            ]}
          >
            <Text style={{ color: "white", fontSize: 25 }}>Exit Tutorial</Text>
          </Pressable>
          {tutorialStage !== 9 ? (
            <Pressable
              elevation={BUTTON_ELEVATION}
              style={[
                startPageStyles.button,
                startPageStyles.shadow,
                { marginTop: 10 },
              ]}
              onPress={() => setTutorialStage(tutorialStage + 1)}
            >
              <Text style={{ color: "white", fontSize: 25 }}>Continue</Text>
            </Pressable>
          ) : null}
        </View>
      </View>
      <View
        style={{
          flex: 8,
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {tutorialMainDisplay}
        {/* <Pressable
          onPress={() => setTutorialStage(tutorialStage + 1)}
          style={{}}
        >
          <Text>Next Tutorial Page</Text>
        </Pressable> */}
      </View>
    </View>
  );
};

const UpdatingPage = (props: any) => {
  const displayText = `Don't ${PRESSCLICK} ${props.badNumber}\n("${
    numberSoundMap[props.badNumber]
  }" sound)`;
  if (props.useAudio) {
    setTimeout(async () => {
      props.newGoalAudio.playSound(1000);
      await sleep(1000);
      props.instructionsAudioFiles[props.badNumber].playSound(2000);
    }, 1);
  }
  return (
    <View
      style={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        flex: 1,
        // height: "100%",
        // width: 250
        // backgroundColor: "white",
      }}
    >
      <View
        elevation={VIEW_ELEVATION}
        style={{
          backgroundColor: "white",
          borderRadius: 10,
          padding: 22,
          marginBottom: 150,
          shadowRadius: 2,
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.8,
        }}
      >
        <Text
          style={{
            backgroundColor: "white",
            borderRadius: 10,
            fontSize: 20,
            textAlign: "center",
            textDecorationLine: "underline",
          }}
        >
          New Goal:
        </Text>
        <Text style={{ fontSize: 30, textAlign: "center" }}>{displayText}</Text>
      </View>
    </View>
  );
};

const StartPage = (props: any) => {
  const soundParens = ` ("${numberSoundMap[props.badNumber]}" sound)`;
  const [tutorialMode, setTutorialMode] = useState(props.tutorialMode);
  const [continuousMode, setContinuousMode] = useState(props.continuousMode);

  const startButtonText = tutorialMode ? "Start Tutorial" : "Start";
  const tutorialButtonText = tutorialMode ? "Practice Mode" : "Start Tutorial";

  useEffect(() => {
    setTutorialMode(props.tutorialMode);
  }, [props.tutorialMode]);

  useEffect(() => {
    setContinuousMode(props.continuousMode);
  }, [props.continuousMode]);

  const [showTypeExplanation, setShowTypeExplanation] = useState(false);

  const handlePress = useLinkPressHandler("/settings");

  return (
    <View
      style={{
        flexDirection: "column",
        justifyContent: "space-around",
        // height: "100%",
        flex: 1,
        // width: "100%",
        // flexGrow: 1,
      }}
    >
      <View
        style={{
          flex: 2,
          justifyContent: "center",
        }}
      >
        <View
          elevation={VIEW_ELEVATION}
          style={{
            backgroundColor: "white",
            borderRadius: 10,
            shadowRadius: 2,
            shadowOffset: { width: 0, height: 1 },
            shadowOpacity: 0.8,
            flexDirection: "column",
            justifyContent: "center",
            paddingVertical: 10,
          }}
        >
          <View>
            <Text
              style={[
                // startPageStyles.h1,
                {
                  textAlign: "left",
                  fontSize: 22,
                  marginHorizontal: 16,
                  color: "hsla(190, 0%, 35%, 1)",
                },
              ]}
            >
              Instructions:
            </Text>
            <Text
              style={[
                {
                  fontSize: 24,
                  textAlign: "left",
                  marginHorizontal: 16,
                  width: 250,
                },
              ]}
            >
              Press every number except for {props.badNumber}
              {props.useAudio ? soundParens : ""}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          // backgroundColor: "red",
        }}
      >
        <View>
          <Pressable
            elevation={BUTTON_ELEVATION}
            onPress={props.handleStart}
            style={[
              startPageStyles.button,
              startPageStyles.shadow3,
              { margin: 10 },
            ]}
          >
            <Text style={startPageStyles.button}>{startButtonText}</Text>
          </Pressable>
        </View>
        <Text
          style={{ textAlign: "center", fontSize: 20, marginTop: 5 }}
          onPress={() => setShowTypeExplanation(!showTypeExplanation)}
        >
          {continuousMode ? "Continuous Mode " : "Perfection Mode "}
          <Octicon name="question" color="black" size={20}></Octicon>
        </Text>
        {showTypeExplanation ? (
          <View style={{ width: 250 }}>
            <Text style={{ marginTop: 5, marginLeft: 10 }}>
              Continuous Mode keeps going until you press "End"
            </Text>
            <Text style={{ marginTop: 5, marginLeft: 10 }}>
              Perfection Mode stops when you make a mistake{" "}
            </Text>
            <Text style={{ marginTop: 5, marginLeft: 10 }}>
              You can change the mode in{" "}
              <Octicon
                name="gear"
                size={20}
                color="black"
                onPress={handlePress}
              ></Octicon>
            </Text>
          </View>
        ) : null}
      </View>
    </View>
  );
};

const theme = createTheme({
  lightColors: {
    ...Platform.select({
      default: lightColors.platform.android,
      ios: lightColors.platform.ios,
    }),
  },
});

const FailureMessageButton = (props: any) => {
  const [showMessage, setShowMessage] = useState(false);
  const handlePress = () => {
    setShowMessage(true);
  };
  return (
    <View
      style={{
        flexDirection: "column",
        alignItems: "center",
        // flex: 1,
      }}
    >
      <Pressable onPress={handlePress}>
        <Text
          style={
            showMessage
              ? { fontSize: 16, marginTop: 30 }
              : { fontSize: 16, marginTop: 30, textDecorationLine: "underline" }
          }
        >
          Why did this stop?
        </Text>
      </Pressable>
      {showMessage ? (
        <Text style={{ fontSize: 16 }}>{props.failureMessage}</Text>
      ) : (
        <Text> </Text>
      )}
    </View>
  );
};

const DoneTesting = (props: any) => {
  let mainStatsDict: any = [];
  const [bestscoreToday, setbestscoreToday] = useState(props.bestscoreToday);
  const [meanscoreToday, setmeanscoreToday] = useState(props.meanscoreToday);

  useEffect(() => {
    setbestscoreToday(props.bestscoreToday);
  });

  useEffect(() => {
    setmeanscoreToday(props.meanscoreToday);
  });

  const percentCorrectNum =
    props.numberStats["goodNumberPresses"] +
    props.numberStats["totalBadNumber"] -
    props.numberStats["badNumberPresses"];
  const percentCorrectDenom =
    props.numberStats["totalGoodNumber"] + props.numberStats["totalBadNumber"];
  const percentCorrect =
    percentCorrectDenom > 0
      ? Math.round((percentCorrectNum * 100) / percentCorrectDenom)
      : 100;
  // const percentCorrect = 98;
  const percentAvoidBad =
    props.numberStats["totalBadNumber"] > 0
      ? Math.round(
          ((props.numberStats["totalBadNumber"] -
            props.numberStats["badNumberPresses"]) *
            100) /
            props.numberStats["totalBadNumber"]
        )
      : 100;
  // const percentAvoidBad = 93;
  let totalPoints = 0;
  if (props.continuousMode) {
    totalPoints = Math.max(
      computeContinuousModeScore(props.numberStats).pointsscore,
      0
    );
    mainStatsDict = [
      ["Total Points", totalPoints],
      ["% Correct", `${percentCorrect}`],
      ["% Bad numbers \n     avoided", `${percentAvoidBad}`],
    ];
  } else {
    mainStatsDict = [
      ["Best Today:", secondsToMinSec(bestscoreToday)],
      ["Average Today:", secondsToMinSec(meanscoreToday)],
      ["Total Tries Today:", props.totaltrialsToday],
    ];
  }

  return (
    <View
      style={{
        width: learnMoreWidth,
        // height: "80%",
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <CongratsPassedBeginnerModal
        congratsPassedBeginner={props.congratsPassedBeginner}
        resetCongratsPassedBeginner={props.resetCongratsPassedBeginner}
      />
      <ShowHelpModal showHelp={props.showHelp} badNumber={props.badNumber} />
      <View
        style={{
          flexDirection: "column",
          width: "100%",
          // height: "100%",
          flex: 1,
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: 50,
        }}
      >
        {props.clock}
        <View
          style={{
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            flex: 2,
            paddingTop: 50,
          }}
        >
          <Text
            style={{
              fontSize: 50,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            Done
          </Text>
          <View
            elevation={VIEW_ELEVATION}
            style={[
              startPageStyles.shadow,
              {
                width: "90%",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: 10,
                backgroundColor: "white",
              },
            ]}
          >
            <View
              style={{
                width: "90%",
                flexDirection: "column",
                alignItems: "center",

                paddingVertical: 10,
              }}
            >
              {mainStatsDict.map(([key, val]) => (
                <View
                  key={key}
                  style={[
                    styles.doneTestingStats,
                    {
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginTop: key == mainStatsDict[0][0] ? 0 : 5,
                    },
                  ]}
                >
                  <Text
                    style={{ fontSize: 28, color: "hsla(220, 4%, 25%, 1)" }}
                  >
                    {key}
                  </Text>
                  <Text style={{ fontSize: 28 }}>{val}</Text>
                </View>
              ))}
            </View>
          </View>
          {totalPoints === 0 && props.continuousMode ? (
            <Text style={{ width: "90%" }}>
              {" "}
              The formula for points is number correct minus 2 times number
              wrong. If you have 0 points, you may have gotten too many wrong.
            </Text>
          ) : null}
        </View>

        {props.congratsPassedBeginner ||
        (props.continuousMode && totalPoints == 0) ? null : (
          <Tips randomState={props.totaltime} />
        )}
        <Pressable
          elevation={BUTTON_ELEVATION}
          onPress={props.handleReset}
          style={[
            startPageStyles.button,
            startPageStyles.shadow,
            {
              paddingHorizontal: 20,
            },
          ]}
        >
          <Text style={startPageStyles.button}>Reset</Text>
        </Pressable>
        {props.continuousMode ? null : (
          <FailureMessageButton failureMessage={props.failureMessage} />
        )}
        {/* <Button onPress={props.handleReset} title="Reset"/> */}
      </View>
    </View>
  );
};

const TestingDisplay = (props: any) => {
  const [numberStats, setNumberStats] = useState(props.numberStats);
  const [isPressed, setIsPressed] = useState(false);

  //todo if you click and then you press the spacebar, it gives this ugly highlight border. Fix it eventually but not anytime soon

  // useEffect(() => {
  //   if (props.continuousMode) {
  //     setNumberStats(props.numberStats);
  //   }
  // }, [props.numberStats]);

  const touchableRef = useRef(null);

  useEffect(() => {
    if (Platform.OS == "web") {
      document.addEventListener("keydown", handlePressSpace);
      document.addEventListener("keyup", handleUnpressSpace);
    }
  }, []);

  const handlePressSpace = (event: any) => {
    if (event.keyCode == 32) {
      event?.preventDefault();
      if (touchableRef.current && touchableRef.current.props?.onPress) {
        touchableRef.current.props.onPress();
      }
      setIsPressed(true);
    }
  };

  const handleUnpressSpace = (event: any) => {
    if (event.keyCode == 32) {
      event?.preventDefault();
      if (touchableRef.current && touchableRef.current.props?.onPress) {
        touchableRef.current.props.onPress();
      }
      setIsPressed(false);
    }
  };

  if (props.toDisplay) {
    if (props.useAudio) {
      try {
        props.audioFiles[props.toDisplay.toString()].playSound(1000);
      } catch (err) {
        console.log("error playing sound", err);
      }
    }
  }
  const insets = useSafeAreaInsets();

  useEffect(() => {
    if (props.toDisplay) {
      props.setCurrentlyDisplayedNumber(parseInt(props.toDisplay));
    }
  });
  const fontSize = 250; //choose([100, 125, 150, 175, 200, 225, 250, 275, 300]);
  return (
    <View style={styles.testingTopContainer}>
      <View
        style={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          // height: "100%",
          flex: 1,
        }}
      >
        {props.clock}

        {props.slowMode ? (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {props.badNumber == props.currentNumber ? (
              <Text style={{ fontSize: 36 }}>
                <Icon name="circle-with-cross" size={36} color="black" />
                {`Don't Press ${props.badNumber}`}
              </Text>
            ) : props.currentNumber == -1 ? null : (
              <Text style={{ fontSize: 36 }}>
                <Icon name="check" size={36} color="black" />
                {`Press ${props.currentNumber}`}
              </Text>
            )}
          </View>
        ) : null}
        {props.currentNumber != -1 && props.continuousMode ? (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: 25 }}>
              Points:{" "}
              {computeContinuousModeScore(props.numberStats).pointsscore}
            </Text>
          </View>
        ) : null}
        {/* <Button onPress={props.handleCancel} title="Cancel" /> */}

        <TouchableHighlight
          ref={touchableRef}
          onPressIn={props.handlePress}
          style={[
            styles.testingContainer,
            {
              height: "200%",
              backgroundColor: isPressed
                ? "hsla(190, 77%, 70%, 1)"
                : "hsla(190, 77%, 70%, 0)",
            },
          ]}
          underlayColor="hsla(190, 77%, 70%, 1)"
        >
          <View>
            <Text
              style={{
                ...styles.testingText,
                fontSize: fontSize,
                marginBottom: 170,
                textShadowColor: "rgba(0, 0, 0, 1)",
                textShadowRadius: 7,
              }}
            >
              {props.toDisplay}
            </Text>
          </View>
        </TouchableHighlight>
        <Pressable
          onPress={props.endTesting}
          style={[
            startPageStyles.cancelStyle,
            startPageStyles.shadow,
            {
              position: "absolute",
              bottom: insets.bottom + (Platform.OS == "android" ? 75 : 10),
            },
          ]}
        >
          <Text style={{ fontSize: 30 }}>End</Text>
        </Pressable>
      </View>
    </View>
  );
};

const secondsToMinSec = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = Math.floor(seconds) % 60;
  if (minutes > 0) return `${minutes}m ${secondsLeft}s`;
  return `${secondsLeft}s`;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  linkText: {
    fontSize: 20,
    color: "white",
  },
  testingContainer: {
    flex: 1,
    width: "100%",
    // height: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  viewContainer: {
    height: 30,
    flexDirection: "row",
    backgroundColor: "black",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  testingText: {
    color: "white",
  },
  testingTopContainer: {
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    // height: "100%",
    flex: 1,
  },
  doneTestingView: {
    justifyContent: "center",
    alignItems: "center",
  },
  doneTestingStats: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
});

const startPageStyles = StyleSheet.create({
  startDisplay: {
    flexDirection: "column",
    justifyContent: "space-between",
  },
  h1: {
    fontSize: 30,
  },
  p: {
    fontSize: 15,
  },
  cancelStyle: {
    backgroundColor: "hsla(200, 77%, 50%, 1)",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
  },
  button: {
    // backgroundColor: "hsla(227, 88%, 63%, 1)",
    backgroundColor: PRIMARY_BUTTON_COLOR,
    fontSize: 36,
    borderRadius: 10,
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
  },
  secondaryButton: {
    backgroundColor: SECONDARY_BUTTON_COLOR, //"white",
    borderRadius: 10,
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    // borderWidth: 1,
    // borderColor: "grey",
    // borderStyle: "solid",
    padding: 5,
  },
  secondaryButtonText: {
    fontSize: 28,
    // height: 30,
    color: "white",
    // fontWeight: "bold",

    // backgroundColor: "blue",
  },
  shadow: {
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
  },
  shadow2: {
    shadowRadius: 2,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.9,
  },
  shadow3: {
    shadowRadius: 3,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.9,
  },
});

export default TestingPage;
