import React, { Component } from "react";
import {
  StyleSheet,
  AppState,
  Platform,
  Vibration,
  View,
  StatusBar,
} from "react-native";
import PresentationComponent from "./src/presentation_component";
import Model from "./src/model";
import {
  combinedSaveData,
  getTodayDateString,
  getUpdatedTodayAggregateData,
  updateDayData,
  getData,
  storeData,
  getUpdatedTodayContinuousData,
  computeContinuousModeScore,
} from "./src/data_manipulation";
import {
  choose,
  nextSartNumber,
  randomDifferentNumber,
} from "./src/random_generator";
import { soundEffect } from "./src/audio";
import { Session } from "@supabase/supabase-js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { supabase } from "./src/supabase";

import {
  numberSoundMap,
  NUMBER_UPDATE_THRESHOLD,
  WAIT_BETWEEN_DISPLAY_TIME,
  VAL_DISPLAY_TIME,
  SLOW_MODE_WAIT_BETWEEN_DISPLAY_TIME,
  SLOW_MODE_VAL_DISPLAY_TIME,
} from "./src/constants";
import * as Haptics from "expo-haptics";
import { Audio } from "expo-av";
// import { WelcomePage } from "./src/welcome";
import { numberInstructionsAudioFileMap } from "./src/audio";
// import { StripeProvider } from "@stripe/stripe-react-native";
// import { fetchStripeKey } from "./src/payment";
import { SafeAreaProvider } from "react-native-safe-area-context";

// Requirements: https://scienceofbehaviorchange.org/measures/sustained-attention-to-response-task/
type AppProps = {};

import { LogBox } from "react-native";
LogBox.ignoreLogs(["new NativeEventEmitter"]); // Ignore log notification by message
LogBox.ignoreAllLogs(); //Ignore all log notifications

type AppStateObj = {
  currentNumber: number;
  isTesting: boolean;
  doneTesting: boolean;
  showVal: boolean;
  useTTS: boolean;
  totaltime: number;
  averageTime: number;
  bestTime: number;
  allowPress: boolean;
  reactionTime: number;
  updateBadNumber: boolean;
  badNumber: number;
  useAudio: boolean;
  todayString: string;
  startTime: number;
  didCancel: boolean;
  continuousTesting: boolean;
  email: string;
  session: Session | null;
  appState: string;
  bestscoreToday: number;
  worstscoreToday: number;
  meanscoreToday: number;
  totaltrialsToday: number;
  currentlyUpdating: boolean;
  // showLandingPage: boolean;
  loginStatus: string;
  stripePublishableKey: string;
  tutorialMode: boolean;
  failureMessage: string;
  slowMode: boolean;
  continuousMode: boolean;
  continuousModeNumberStats: any;
  hasDoneSave: boolean;
  showHelp: boolean;
};

class App extends Component<AppProps, AppStateObj> {
  model: Model;
  userId: string;
  failFile: any;
  confirmFile: any;
  failSound: soundEffect;
  numberUpdateThreshold: number;
  currentlyDisplayedNumber: number;
  waitBetweenDisplayTime: number;
  valDisplayTime: number;
  badNumberPresses: number;
  totalBadNumber: number;
  goodNumberPresses: number;
  totalGoodNumber: number;
  hasPassedBeginner: boolean;
  congratsPassedBeginner: boolean;
  constructor(props: AppProps) {
    super(props);
    this.currentlyDisplayedNumber = -1;
    this.model = new Model();
    this.failFile = require("./assets/fail_sound.mp3");
    this.confirmFile = require("./assets/C6.mp3");
    this.failSound = new soundEffect(this.failFile);
    this.numberUpdateThreshold = NUMBER_UPDATE_THRESHOLD;
    this.state = {
      failureMessage: "whoops",
      currentNumber: -1,
      isTesting: false,
      doneTesting: false,
      totaltime: this.model.totaltime,
      averageTime: this.model.averageTime,
      bestTime: this.model.bestTime,
      useTTS: true,
      showVal: false,
      allowPress: false,
      updateBadNumber: true,
      badNumber: nextSartNumber(0),
      reactionTime: 0,
      useAudio: false,
      todayString: getTodayDateString(),
      startTime: 0,
      didCancel: false,
      continuousTesting: false,
      email: "",
      session: null,
      appState: AppState.currentState,
      bestscoreToday: 0,
      worstscoreToday: 0,
      meanscoreToday: 0,
      totaltrialsToday: 0,
      currentlyUpdating: false,
      // showLandingPage: Platform.OS === "web",
      loginStatus: "no",
      stripePublishableKey: "",
      tutorialMode: false,
      showHelp: false,

      // continuousMode: true,
      // slowMode: true,
      // todo: change this back to true
      continuousMode: true,
      slowMode: true,
      continuousModeNumberStats: {
        badNumberPresses: 0,
        goodNumberPresses: 0,
        totalBadNumber: 0,
        totalGoodNumber: 0,
      },
      hasDoneSave: false,
    };
    this.userId = "testUser";
    this.badNumberPresses = 0;
    this.totalBadNumber = 0;
    this.goodNumberPresses = 0;
    this.totalGoodNumber = 0;
    (this.hasPassedBeginner = false), (this.congratsPassedBeginner = false);
    this.setSlowMode(this.state.slowMode, false);
  }

  setLoginStatus = (status: string) => {
    this.setState({ loginStatus: status });
  };

  setCurrentlyDisplayedNumber = (number: number) => {
    this.currentlyDisplayedNumber = number;
  };

  setEmail = async (email: string) => {
    this.setState({ email: email });
  };

  setTutorialMode = (tutorialMode: boolean) => {
    this.setState({ tutorialMode: tutorialMode });
  };

  resetStats = () => {
    this.badNumberPresses = 0;
    this.totalBadNumber = 0;
    this.goodNumberPresses = 0;
    this.totalGoodNumber = 0;
    this.updateStatState();
  };

  setSlowMode = (slowMode: boolean, setState: boolean = true) => {
    if (slowMode) {
      this.waitBetweenDisplayTime = SLOW_MODE_WAIT_BETWEEN_DISPLAY_TIME;
      this.valDisplayTime = SLOW_MODE_VAL_DISPLAY_TIME;
    } else {
      this.waitBetweenDisplayTime = WAIT_BETWEEN_DISPLAY_TIME;
      this.valDisplayTime = VAL_DISPLAY_TIME;
    }
    if (setState) {
      this.setState({ slowMode: slowMode });
    }
  };

  setContinuousMode = (continuousMode: boolean) => {
    this.setState({
      continuousMode: continuousMode,
      isTesting: false,
      doneTesting: false,
    });
  };

  toggleBadNumber = () => {
    let newBadNumber = this.state.badNumber;
    if (!this.state.updateBadNumber) {
      const options = [1, 2, 3, 4, 5, 6, 7, 8, 9].filter(
        (n) => n != this.state.badNumber
      );
      newBadNumber = choose(options);
    }
    this.setState({
      updateBadNumber: !this.state.updateBadNumber,
      badNumber: newBadNumber,
    });
  };

  toggleAudio = () => {
    this.setState({ useAudio: !this.state.useAudio });
  };

  toggleContinuousTesting = () => {
    this.setState({ continuousTesting: !this.state.continuousTesting });
  };

  endTesting = () => {
    this.setState({
      currentNumber: -1,
      isTesting: true,
      doneTesting: true,
      showVal: false,
      allowPress: false,
      didCancel: false,
      totaltime: (new Date().getTime() - this.model.startTime) / 1000,
    });
    this.model.reset();
    if (!this.hasPassedBeginner) {
      this.checkIfPassedBeginner();
    }
  };

  checkIfShouldShowHelp = () => {
    const currentScore = computeContinuousModeScore({
      goodNumberPresses: this.goodNumberPresses,
      totalGoodNumber: this.totalGoodNumber,
      badNumberPresses: this.badNumberPresses,
      totalBadNumber: this.totalBadNumber,
      totaltime: this.state.totaltime,
    }).pointsscore;
    if (currentScore < -4) {
      this.setState({ showHelp: true });
      this.endTesting();
    }
  };

  checkIfPassedBeginner = () => {
    if (
      this.goodNumberPresses >= 3 &&
      this.totalBadNumber - this.badNumberPresses >= 1
    ) {
      // if (true) {
      this.hasPassedBeginner = true;
      this.congratsPassedBeginner = true;
      storeData("hasPassedBeginner", true);
      this.setState({ slowMode: false });
      return true;
    }
    return false;
  };

  reset = (pauseContinuousTesting = false) => {
    this.congratsPassedBeginner = false;
    this.model.reset();
    const newBadNumber = this.state.updateBadNumber
      ? randomDifferentNumber(this.state.badNumber)
      : this.state.badNumber;
    this.setState({
      currentNumber: -1,
      isTesting: false,
      doneTesting: false,
      showVal: false,
      allowPress: false,
      totaltime: 0,
      badNumber: newBadNumber,
      didCancel: true,
      hasDoneSave: false,
      showHelp: false,
    });
    if (pauseContinuousTesting) {
      this.setState({ continuousTesting: false });
    }
  };

  handleStart = async () => {
    this.model.startTime = new Date().getTime();
    this.resetStats();
    const slowdownFactor = this.state.tutorialMode ? 2.5 : 1;
    this.setState({
      isTesting: true,
      startTime: this.model.startTime,
      didCancel: false,
    });
    if (this.state.useAudio) {
      numberInstructionsAudioFileMap[this.state.badNumber].playSound(3000);
    }
    const fullLoopTime =
      (this.valDisplayTime + this.waitBetweenDisplayTime) * slowdownFactor;
    let loopDelay = this.state.useAudio ? 2000 : 1000; // Allows pause before it begins
    this.model.pendingTimeouts.push(
      setTimeout(() => {
        this.setState({ allowPress: true });
      }, loopDelay)
    );
    let doneTestingRedundant = false;
    let lastNumberUpdateTimestamp = new Date().getTime();
    while (!this.state.doneTesting && !doneTestingRedundant) {
      this.checkIfShouldShowHelp();
      this.model.pendingTimeouts = [];
      this.model.didAlreadyPress = false;
      let allowBadNumber =
        (new Date().getTime() - lastNumberUpdateTimestamp) / 1000 > 10;
      let mustHaveBadNumber =
        !this.hasPassedBeginner &&
        this.state.slowMode &&
        allowBadNumber &&
        (new Date().getTime() - lastNumberUpdateTimestamp) / 1000 < 14;
      const nextNum = mustHaveBadNumber
        ? this.state.badNumber
        : nextSartNumber(
            this.state.badNumber,
            allowBadNumber,
            this.state.slowMode
          );
      this.model.pendingTimeouts.push(
        setTimeout(() => {
          if (nextNum == this.state.badNumber) {
            this.totalBadNumber += 1;
          } else {
            this.totalGoodNumber += 1;
          }
          this.setState(
            {
              currentNumber: nextNum,
              showVal: true,
            },
            () => {
              this.model.lastNumberStartTime = new Date().getTime();
            }
          );
        }, 1 + loopDelay)
      );
      this.model.pendingTimeouts.push(
        setTimeout(() => {
          this.setState({
            showVal: false,
          });
        }, this.valDisplayTime * slowdownFactor + loopDelay)
      );
      this.model.pendingTimeouts.push(
        setTimeout(() => {
          let didFail = false;
          if (!this.model.didAlreadyPress) {
            if (this.state.currentNumber !== this.state.badNumber) {
              // missed pressing the good number
              if (!this.state.continuousMode) {
                didFail = true;
                doneTestingRedundant = true;
                if (this.state.useAudio) this.failSound.playSound(200);
                this.setState({
                  failureMessage: `You needed to press ${this.state.currentNumber}, but did not press it in time`,
                });
              }
            }
          }

          this.model.totaltime =
            (new Date().getTime() - this.model.startTime) / 1000;
          if (this.model.startTime > 0 && !this.state.continuousMode) {
            this.setState({
              doneTesting: didFail,
              totaltime: this.model.totaltime,
            });
          }
          this.updateStatState();
        }, fullLoopTime + loopDelay)
      );
      await new Promise((resolve, reject) => {
        this.model.pendingTimeouts.push(
          setTimeout(resolve, fullLoopTime + loopDelay)
        );
      });
      loopDelay = 0;
      if (
        new Date().getTime() - lastNumberUpdateTimestamp >
        this.numberUpdateThreshold
      ) {
        this.setState({
          currentlyUpdating: true,
          badNumber: randomDifferentNumber(this.state.badNumber),
        });
        await new Promise((resolve, reject) => {
          this.model.pendingTimeouts.push(setTimeout(resolve, 4000));
        });
        this.setState({ currentlyUpdating: false });
        lastNumberUpdateTimestamp =
          lastNumberUpdateTimestamp + this.numberUpdateThreshold;
      }
    }
  };

  componentDidMount() {
    AppState.addEventListener("change", this._handleAppStateChange);
    Audio.setAudioModeAsync({ playsInSilentModeIOS: true });
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        // TODO sync local data with supabase data
        this.setState({ loginStatus: "yes", session: session });
      }
    });

    // fetchStripeKey((key) => this.setState({ stripePublishableKey: key }));
    getData("hasPassedBeginner").then((hasPassedBeginner) => {
      if (hasPassedBeginner === true) {
        this.hasPassedBeginner = hasPassedBeginner;
        this.forceUpdate();
        this.setSlowMode(false);
      } else {
        this.hasPassedBeginner = false;
        this.setSlowMode(true);
      }
    });
    this.congratsPassedBeginner = false;
    AsyncStorage.clear();
    // updateDayData();
    // updateDataStorageFormatv2();
    // saveAggregateDataToLocal();
    // updateDataStorageFormat();
  }

  setSession = (session: any) => {
    this.setState({ session: session });
  };

  updateStatState = () => {
    this.setState({
      continuousModeNumberStats: {
        goodNumberPresses: this.goodNumberPresses,
        totalGoodNumber: this.totalGoodNumber,
        badNumberPresses: this.badNumberPresses,
        totalBadNumber: this.totalBadNumber,
      },
    });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      !prevState.doneTesting &&
      this.state.doneTesting &&
      !this.state.tutorialMode &&
      !this.state.hasDoneSave
    ) {
      this.model.addNewScore(this.model.totaltime);
      this.setState({
        // totaltime: this.model.totaltime,
        averageTime: this.model.averageTime,
        bestTime: this.model.bestTime,
        hasDoneSave: true,
      });
      if (this.state.continuousMode) {
        // this combines current game stats
        // with best stats from today
        getUpdatedTodayContinuousData({
          goodNumberPresses:
            this.state.continuousModeNumberStats.goodNumberPresses,
          totalGoodNumber: this.state.continuousModeNumberStats.totalGoodNumber,
          badNumberPresses:
            this.state.continuousModeNumberStats.badNumberPresses,
          totalBadNumber: this.state.continuousModeNumberStats.totalBadNumber,
          totaltime: this.state.totaltime,
        })
          .then((toSave) => {
            if (toSave["totaltime"] > 0) {
              combinedSaveData(toSave, this.state.session, true);
            }
          })
          .catch((error) =>
            console.log("error saving data continuous mode", error)
          );
      } else {
        getUpdatedTodayAggregateData(this.state.totaltime).then((toSave) => {
          this.setState({
            bestscoreToday: toSave.bestscoretoday,
            worstscoreToday: toSave.worstscoretoday,
            meanscoreToday: toSave.meanscoretoday,
            totaltrialsToday: toSave.totaltrialstoday,
          });
          combinedSaveData(toSave, this.state.session, false);
          this.model.reset();
        });
      }

      if (this.state.continuousTesting) {
        setTimeout(() => {
          this.reset();
        }, 750);
        setTimeout(() => {
          this.handleStart();
        }, 1500);
      }

      if (prevState.slowMode != this.state.slowMode) {
        this.valDisplayTime = this.state.slowMode
          ? SLOW_MODE_VAL_DISPLAY_TIME
          : VAL_DISPLAY_TIME;
        this.waitBetweenDisplayTime = this.state.slowMode
          ? SLOW_MODE_WAIT_BETWEEN_DISPLAY_TIME
          : WAIT_BETWEEN_DISPLAY_TIME;
      }
    }
  }

  handlePress = () => {
    if (!this.state.allowPress) return;
    if (this.currentlyDisplayedNumber === this.state.badNumber) {
      if (!this.model.didAlreadyPress) this.badNumberPresses++;
      if (!this.state.continuousMode) {
        this.model.totaltime =
          (new Date().getTime() - this.model.startTime) / 1000;
        let failureMessage = `You pressed ${this.state.badNumber}`;
        if (this.state.useAudio) {
          failureMessage += ` (${numberSoundMap[this.state.badNumber]} sound)`;
        }
        failureMessage += ` but were not supposed to.`;
        this.setState({
          doneTesting: true,
          failureMessage: failureMessage,
        });
        // TODO I think this is unnecessary
        for (let timeout of this.model.pendingTimeouts) {
          clearTimeout(timeout);
        }
        if (this.state.useAudio) this.failSound.playSound(200);
      }
    } else {
      if (!this.model.didAlreadyPress) this.goodNumberPresses++;
      if (Platform.OS == "ios") Haptics.selectionAsync();
      if (Platform.OS == "android") Vibration.vibrate(25);
    }
    this.model.didAlreadyPress = true;
    if (!this.hasPassedBeginner) {
      if (this.checkIfPassedBeginner()) {
        this.endTesting();
      }
    }
    this.updateStatState();
  };

  _handleAppStateChange = (nextAppState: any) => {
    if (
      nextAppState.match(/inactive|background/) &&
      this.state.isTesting &&
      !this.state.doneTesting
    ) {
      this.endTesting();
    }
    this.setState({ appState: nextAppState });
  };

  resetCongratsPassedBeginner = () => {
    this.congratsPassedBeginner = false;
    this.forceUpdate();
  };

  render() {
    return (
      <SafeAreaProvider>
        {/* <StripeProvider publishableKey={this.state.stripePublishableKey}> */}
        <StatusBar hidden />
        <PresentationComponent
          handlePress={this.handlePress}
          handleStart={this.handleStart}
          handleCancel={() => this.reset(true)}
          handleReset={() => this.reset()}
          toggleBadNumber={this.toggleBadNumber}
          toggleAudio={this.toggleAudio}
          setSession={this.setSession}
          toggleContinuousTesting={this.toggleContinuousTesting}
          setEmail={this.setEmail}
          isTesting={this.state.isTesting}
          doneTesting={this.state.doneTesting}
          useTTS={this.state.useTTS}
          totaltime={this.state.totaltime}
          toDisplay={`${this.state.showVal ? this.state.currentNumber : ""}`}
          reactionTime={this.state.reactionTime}
          badNumber={this.state.badNumber}
          currentNumber={this.state.currentNumber}
          updateBadNumber={this.state.updateBadNumber}
          useAudio={this.state.useAudio}
          todayString={this.state.todayString}
          startTime={this.state.startTime}
          didCancel={this.state.didCancel}
          continuousTesting={this.state.continuousTesting}
          email={this.state.email}
          serverUrl={this.model.serverUrl}
          bestscoreToday={this.state.bestscoreToday}
          worstscoreToday={this.state.worstscoreToday}
          meanscoreToday={this.state.meanscoreToday}
          totaltrialsToday={this.state.totaltrialsToday}
          currentlyUpdating={this.state.currentlyUpdating}
          loginStatus={this.state.loginStatus}
          setLoginStatus={this.setLoginStatus}
          session={this.state.session}
          setTutorialMode={this.setTutorialMode}
          tutorialMode={this.state.tutorialMode}
          setCurrentlyDisplayedNumber={this.setCurrentlyDisplayedNumber}
          failureMessage={this.state.failureMessage}
          slowMode={this.state.slowMode}
          setSlowMode={this.setSlowMode}
          continuousMode={this.state.continuousMode}
          setContinuousMode={this.setContinuousMode}
          resetCongratsPassedBeginner={() => this.resetCongratsPassedBeginner()}
          numberStats={
            // goodNumberPresses: this.goodNumberPresses,
            // totalGoodNumber: this.totalGoodNumber,
            // badNumberPresses: this.badNumberPresses,
            // totalBadNumber: this.totalBadNumber,
            this.state.continuousModeNumberStats
          }
          endTesting={this.endTesting}
          hasPassedBeginner={this.hasPassedBeginner}
          congratsPassedBeginner={this.congratsPassedBeginner}
          showHelp={this.state.showHelp}
        />
        {/* </StripeProvider> */}
      </SafeAreaProvider>
    );
  }
}
// }

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default App;
