import Model from "./model";
import { Platform, Dimensions, View, SafeAreaView } from "react-native";
import React from "react";
import {} from "react-native";
import Svg, { Path, Rect } from "react-native-svg";

interface handleFunc {
  (): void;
}

interface PresentationComponentProps {
  handlePress: handleFunc;
  handleStart: handleFunc;
  handleReset: handleFunc;
  setLoginStatus: handleFunc;
  setEmail: any;
  handleCancel: any;
  toggleBadNumber: handleFunc;
  toggleAudio: handleFunc;
  setSession: handleFunc;
  toggleContinuousTesting: handleFunc;
  toDisplay: string;
  isTesting: boolean;
  doneTesting: boolean;
  useTTS: boolean;
  totaltime: number;
  reactionTime: number;
  badNumber: number;
  updateBadNumber: boolean;
  useAudio: boolean;
  todayString: string;
  startTime: number;
  didCancel: boolean;
  continuousTesting: boolean;
  email: string;
  serverUrl: string;
  bestscoreToday: number;
  worstscoreToday: number;
  meanscoreToday: number;
  totaltrialsToday: number;
  currentlyUpdating: boolean;
  loginStatus: string;
  setTutorialMode: handleFunc;
  tutorialMode: boolean;
  failureMessage: string;
}

const numberSoundMap: { [key: string]: string } = {
  "1": "ga",
  "2": "sha",
  "3": "ba",
  "4": "ta",
  "5": "ma",
  "6": "wa",
  "7": "na",
  "8": "ha",
  "9": "ka",
};

const learnMoreWidthPercent = 95;
const learnMoreWidth = Math.min(
  500,
  Dimensions.get("window").width * (learnMoreWidthPercent / 100)
);

// const DARKGREEN1 = "#184D17";
// const BACKGROUNDGREEN1 = "#3f9a3e";
// const BACKGROUNDGREEN2 = "#4fc04d";
const DARKGREEN1 = "hsl(0, 0%, 50%)";
const BACKGROUNDGREEN1 = "hsl(0, 0%, 80%)";
const BACKGROUNDGREEN2 = "hsl(0, 0%, 80%)";
const PRIMARY_BUTTON_COLOR = "hsla(227, 88%, 63%, 1)";
const SECONDARY_BUTTON_COLOR = "hsla(210, 77%, 65%, 1)";
const SECONDARY_BUTTON_COLOR_PRESSED = "hsla(200, 70%, 45%, 1)";
const WAIT_BETWEEN_DISPLAY_TIME = 150; //900;
const VAL_DISPLAY_TIME = 1000; //250;
const SLOW_MODE_WAIT_BETWEEN_DISPLAY_TIME = 200; //1000;
const SLOW_MODE_VAL_DISPLAY_TIME = 2675; // 1875;
// const VAL_DISPLAY_TIME = 1000000;
const NUMBER_UPDATE_THRESHOLD = 300000;
// const NUMBER_UPDATE_THRESHOLD = 5000;
const PRESSCLICK = Platform.OS === "web" ? "click" : "press";
const freeTrialLength = 14;
const graphWidth = 90;
const BACKGROUNDBLUE1 = "hsla(190, 80%, 64%, 1)";
const BACKGROUNDBLUE2 = "hsla(190, 77%, 50%, 1)";
const BUTTON_ELEVATION = 10;
const VIEW_ELEVATION = 10;
const CONTINUOUS_MODE_TIME_THRESHOLD = 5 * 60;
const TOO_MANY_SECONDS = 60 * 60 * 24 * 365;

const Background = ({ children, customStyles }: any) => {
  const screenHeight = Dimensions.get("window").height;
  return (
    <View style={[customStyles, { backgroundColor: BACKGROUNDBLUE1 }]}>
      <View
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        <Svg
          height="100%"
          width="100%"
          viewBox="0 0 1440 200"
          style={{ position: "absolute" }}
        >
          <Path
            fill={BACKGROUNDBLUE2}
            d="M0,128L48,117.3C96,107,192,85,288,74.7C384,64,480,64,576,101.3C672,139,768,213,864,229.3C960,245,1056,203,1152,154.7C1248,107,1344,53,1392,26.7L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          />
          <Rect
            x="0"
            y="320"
            width="100%"
            height={3 * screenHeight}
            fill={BACKGROUNDBLUE2}
          />
        </Svg>
        <SafeAreaView style={{ height: "100%" }}>{children}</SafeAreaView>
      </View>
    </View>
  );
};

const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export {
  handleFunc,
  PresentationComponentProps,
  numberSoundMap,
  learnMoreWidth,
  DARKGREEN1,
  BACKGROUNDGREEN1,
  BACKGROUNDGREEN2,
  sleep,
  WAIT_BETWEEN_DISPLAY_TIME,
  VAL_DISPLAY_TIME,
  learnMoreWidthPercent,
  PRESSCLICK,
  freeTrialLength,
  graphWidth,
  Background,
  SECONDARY_BUTTON_COLOR,
  SECONDARY_BUTTON_COLOR_PRESSED,
  BACKGROUNDBLUE1,
  BACKGROUNDBLUE2,
  BUTTON_ELEVATION,
  VIEW_ELEVATION,
  NUMBER_UPDATE_THRESHOLD,
  PRIMARY_BUTTON_COLOR,
  SLOW_MODE_WAIT_BETWEEN_DISPLAY_TIME,
  SLOW_MODE_VAL_DISPLAY_TIME,
  CONTINUOUS_MODE_TIME_THRESHOLD,
  TOO_MANY_SECONDS,
};
